import PropTypes from 'prop-types'
import { Image } from '@ui'

const ListItem = ({ image, number, title }) => {
    const resolveTitle = () => {
        return `<span>${number + 1}.</span>${title}`
    }

    return (
        <li className="HowTo__item">
            {image && image.src && <Image src={'/' + image.src} alt={image.alt} />}
            <h3
                className="HowTo__title"
                dangerouslySetInnerHTML={{ __html: resolveTitle() }}
            />
        </li>
    )
}

ListItem.propTypes = {
    image: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string
    }),
    number: PropTypes.number,
    title: PropTypes.string
}

export default ListItem
