import useTranslation from 'next-translate/useTranslation'

import { Container, TextBlock, Grid, Col, Link } from '@ui'

const Footer = () => {
    const { t, lang } = useTranslation('common')
    console.log({ lang })

    return (
        <footer className="Footer py-4 m:pb-5 m:pt-13">
            <Container>
                <Grid>
                    <Col size="50%">
                        <TextBlock className="font-light">
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: t('footer_copy')
                                }}
                            />
                        </TextBlock>
                    </Col>
                    <Col size="50%" className="m:mb-4">
                        <nav className="Footer__nav">
                            <ul>
                                <li>
                                    <Link
                                        to="/privacy-policy"
                                        color="black"
                                        text={t('footer_link_privacy_policy')}
                                    />
                                </li>
                                <li>
                                    <Link
                                        to="/terms-of-service"
                                        color="black"
                                        text={t('footer_link_terms_of_use')}
                                    />
                                </li>
                                {['lt', 'en'].includes(lang) && (
                                    <li>
                                        <Link
                                            to="/service-provision"
                                            color="black"
                                            text={t('footer_link_procedures')}
                                        />
                                    </li>
                                )}
                                <li>
                                    <Link
                                        to="/about"
                                        color="black"
                                        text={t('footer_link_about')}
                                    />
                                </li>
                                <li>
                                    <Link
                                        to="/contacts"
                                        color="black"
                                        text={t('footer_link_contacts')}
                                    />
                                </li>
                            </ul>
                        </nav>
                    </Col>
                </Grid>
            </Container>
        </footer>
    )
}

export default Footer
