import { stringify } from '@utils/qs'
import { serialize } from 'object-to-formdata'
//import useAppState from 'utils/state'
import axios from 'axios'

export function getUrl(endpoint, query = {}) {
    const separator = !query || Object.keys(query).length === 0 ? '' : '?'
    return `${
        process.env.NEXT_PUBLIC_API_URL
    }${endpoint}${separator}${stringify(query)}`
}

export async function post(endpoint, data) {
    const url = getUrl(endpoint)
    const body =
        data instanceof HTMLElement
            ? new FormData(data)
            : serialize(data, { indices: true, booleansAsIntegers: true })

//             const dataObject = {};
// for (let [key, value] of body.entries()) {
//     dataObject[key] = value;
// }

// console.log(dataObject);
//             return
    try {
        return await axios.post(url, body)
    } catch (err) {
        return err.response
    }
}

export async function get(endpoint, query) {
    const url = getUrl(endpoint, query)

    try {
        return await axios.get(url)
    } catch (err) {
        return err.response
    }
}
