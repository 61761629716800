const items = [
    {
        question: 'What is Deklaruok24.lt?',
        answer: (
            <p>Deklaruok24.lt is a project of the customs broker {' '}
                <a target="_blank" href="https://trevio.eu" rel="noreferrer">
                    UAB "Trevio"
                </a>{' '}. In cooperation with carriers operating in Lithuania, the Deklaruok24.lt system helps carriers and consignees to process import declarations at Lithuanian customs. All participants in the process are assured of an automated and efficient process from the submission of the consignment data to the customs authorities until the declaration is finalised. Our aim is to make sure that the recipient doesn't have to worry and receives their consignment quickly.
            </p>
        ),
        active: true
    },
    {
        question: 'Why do I need to declare my purchases?',
        answer: (
            <p>
                As of 1 July 2021, all goods purchased from non-EU countries, as well as throughout Europe, must be subject to import VAT on the value of EUR 0.01.
            </p>
        )
    },
    {
        question: 'When do consignments need to be declared to customs?',
        answer: (
            <p>
                Shipments you send from outside the EU must be declared at customs. You will get information on when to declare your shipments to customs from your carrier or the customs broker who will deliver your consignments to you.
            </p>
        )
    },
    {
        question: 'How are shipments declared?',
        answer: (
            <p>
                Consignment carriers or consignees must declare the consignments to customs by lodging electronic customs declarations. The manifest24.lt system processes the data of the shipments provided by Lithuanian carriers, informs you about the fees payable, and, after you have paid them, declares the consignment to the customs authorities, and informs the carrier that the consignment can be delivered to you.
            </p>
        )
    },
    {
        question: 'How are taxes and VAT calculated?',
        answer: (
            <>
                <p>
                Where the (intrinsic) value of the goods in a consignment, i.e. the amount paid for the goods, is less than EUR 150, the duty shall be calculated as follows:
                </p>
                <p>
                • the customs value of the goods is determined as the sum of the value of the goods plus the cost of shipping and insurance, e.g. if the value of the goods is €10 and the cost of shipping and insurance is €30, the customs value of the goods is €40;<br/>
                • import VAT of 21% is charged on the customs value of the goods.
                </p>
                <p>
                    <strong>
                If the intrinsic (fair) value of the goods is more than €150 EUR
                    </strong>
                </p>
                <p>
                • customs value of the goods is determined; • the customs duty shall be calculated in accordance with the laws of the Republic of Lithuania; • if the goods are excisable, the excise duty is charged; • the customs value of the goods, customs duties and excise duties are added together to calculate import VAT at the rate of 21%.
                </p>
                <p>
                The Deklaruok24.lt system automatically identifies the customs value and the nature of your consignment, taking into account all the costs incurred by the carrier, and provides a breakdown of these charges in the system.
                </p>
                <p>
                <strong>IMPORTANT:</strong> If you pay VAT at the time of purchase (this is the case when you buy from the major e-shops, which tell you when you place your order in their system), you will not have to pay import VAT in Lithuania.
                </p>
            </>
        )
    },
    {
        question: 'What is IOSS and non-IOSS?',
        answer: (
            <>
                <p>This is a special EU-designed scheme for imported goods, called the Import One-Stop Shop (IOSS), where VAT is paid to the seller when the goods are bought in a third country.</p>
 
                <p>If the goods are purchased using this system, the seller will be responsible for paying the VAT on the goods purchased by the buyer, i.e. the buyer will pay the VAT to the seller at the time of purchase and the seller will have to remit the tax collected to the EU Member State to which the consignment is sent.</p>           

                <p>If the goods are purchased without using the IOSS scheme, the purchase may be subject to a special procedure for declaring and paying import VAT, with the import VAT being paid when the goods are shipped to Lithuania and declared to customs.</p>
            </>
        )
    },
    {
        question: 'Is there a charge for the declaration service through this system?',
        answer: (
            <p>
                Yes, the service is paid and the price depends on the carrier who delivers the consignment. You will see the price in our system at the time of declaration.
            </p>
        )
    },
    {
        question: 'How long does it take to declare consignments through this system?',
        answer: (
            <p>
                The time depends on whether there is missing information about the shipment and whether the consignment is withheld by the customs. If no information is missing, declaration can take few minutes using this system. If there is a shortage, it depends on how quickly you provide the missing information, and also on whether your consignment has been withheld by customs (in case of suspicions).
            </p>
        )
    },
    {
        question: 'What happens if I do not provide the requested documents?',
        answer: (
            <p>
                If you receive a notification from the Deklaruok24.lt system to provide additional documents, and if you refuse to provide them or do not provide them within a timeframe acceptable to the customs authorities, the carrier will return the consignment to the consignor.
            </p>
        )
    },
    {
        question: 'What happens if I do not pay the taxes?',
        answer: (
            <p>
                If you do not pay the estimated charges within a reasonable time after the expiry of the storage period, the consignment will be returned to the consignor.
            </p>
        )
    },
    {
        question: 'How much time do I have to pay the consignment taxes?',
        answer: (
            <p>
                We recommend that you do this no later than 5 days after the goods arrive at the warehouse. Payment affects the speed of consignment delivery, so if you want to receive your consignment sooner, we recommend that you also pay your taxes sooner. If payment is not received within 5 days of arrival at the warehouse, the consignment is returned to the sender on the 6th day. It should be noted that you are sent a payment notification before the goods arrive at the warehouse. Normally, the notification is sent 2-3 weeks before arrival at the warehouse.
            </p>
        )
    },
    {
        question:
            'What is the liability if I change the price of a consignment to a lower price in the system?',
        answer: (
            <p>
                False declarations may result in a fine (provided for in Articles 211-212 of the Code of Administrative Offences).
            </p>
        )
    },
    {
        question:
            'Can I recover the import taxes I paid if the goods are not suitable and I want to return them to the seller?',
        answer: (
            <p>
                Yes, this is possible by completing an export declaration and submitting a customs application. There will be an additional cost for a customs broker’s service.
            </p>
        )
    },
    {
        question: 'Can I refuse the consignment?',
        answer: (
            <p>
                Yes. You can refuse the consignment in the first step of the Declare a consignment declaration on Deklaruok24.lt.
            </p>
        )
    },
    {
        question:
            'What if the data displayed when declaring a consignment do not match?',
        answer: (
            <p>
                You can submit additional data or a correction of the data in the first step of declaring a consignment on Deklaruok24.lt.
            </p>
        )
    },
    {
        question: 'Can I declare elsewhere?',
        answer: (
            <p>
                Yes, you can declare your consignment before it arrives at the distribution warehouse, either by declaring it yourself via the Customs Self-Service Portal or via another customs broker. The first step in declaring a consignment on Deklaruok24.lt is the possibility to save the existing digital consignment data.
            </p>
        )
    },
    {
        question: 'Whom can I contact if I have additional questions?',
        answer: (
            <p>
                For all enquiries, please contact us by email: {' '}<a href="mailto:info@deklaruok24.lt">info@deklaruok24.lt</a>.
            </p>
        )
    },
    {
        question:
            'If the consignment value is indicated in a foreign currency, how is it converted into euro for the purpose of calculating import duties?',
        answer: (
            <>
            <p>The customs value in euro of imported goods valued in a foreign currency shall be calculated at the exchange rate applicable on the date of acceptance of the import declaration.</p>
            <p> The current exchange rate between the euro and other currencies shall be determined in accordance with the procedure laid down in Article 146 of the implementing act of the Union Customs Code. The exchange rate between the euro and other currencies is published on the website of the {' '}
                    <a
                        href="http://www.lrmuitine.lt/web/guest/767"
                        target="_blank"
                        rel="noreferrer"
                    >Lithuanian Customs.</a></p>
            </>
        )
    }
]

export default items
