import PropTypes from 'prop-types'
import cx from '@utils/classNames'

const Image = ({ src, alt, className, ...rest }) => {
    return (
        <div className={cx('Image', className)} {...rest}>
            <img src={src} alt={alt} />
        </div>
    )
}

Image.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    className: PropTypes.string
}

export default Image
