import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import useMobile from '@utils/useMobile'
import { Link } from '@ui'
import { ReactComponent as CheckIcon } from '../assets/icons/check-icon.svg'
import cx from 'utils/classNames'
import useAppState from '@utils/state'

const SideNav = ({ disabled }) => {
    const { t } = useTranslation('declare')
    const { pathname } = useRouter()
    const { isMobile } = useMobile()
    const { shipment } = useAppState()
    const { payment_needed } = shipment

    return (
        <ul className={cx('SideNav', disabled && 'SideNavDisabled')}>
            <li>
                <Link
                    to="/declare/1"
                    text={
                        <>
                            <span>1.</span>
                            {t('sidebar_step_1')}
                        </>
                    }
                    color="grey-dark"
                    active={pathname === '/declare/1'}
                />
            </li>
            <li>
                <Link
                    to="/declare/2"
                    text={
                        <>
                            <span>2.</span>
                            {t('sidebar_step_2')}
                        </>
                    }
                    color="grey-dark"
                    active={pathname === '/declare/2'}
                />
            </li>
            {payment_needed && (
                <li>
                    <Link
                        to="/declare/3"
                        text={
                            <>
                                <span>3.</span>
                                {t('sidebar_step_3')}
                            </>
                        }
                        color="grey-dark"
                        active={pathname === '/declare/3'}
                    />
                </li>
            )}
            {!isMobile && (
                <li>
                    <Link
                        to="/declare/4"
                        text={
                            <>
                                <span>
                                    <CheckIcon
                                        width="1.7rem"
                                        style={{ fill: 'currentcolor' }}
                                    />
                                </span>
                                {t('sidebar_step_4')}
                            </>
                        }
                        color="grey-dark"
                        active={pathname === '/declare/4'}
                    />
                </li>
            )}
        </ul>
    )
}

export default SideNav
