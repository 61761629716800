import useTranslation from 'next-translate/useTranslation'
import { Heading, TextArea, Button, Col } from '@ui'
import { Message, DataForm } from '@components'
import { useState } from 'react'
import parseFormEvent from '@utils/parse'
import { post } from '@utils/api'
import useAppState from '@utils/state'

export default function WrongData({
    modal2Active,
    handleHideModal,
    onSuccess
}) {
    const { shipment } = useAppState()
    const { t } = useTranslation('declare')
    const [data, setData] = useState({
        text: '',
        file_1: '',
        file_2: '',
        file_3: ''
    })

    const handleSubmitModal = async (e) => {
        e.preventDefault()

        let size = 0
        if (data.file_1) {
            size += data.file_1.size
        }
        if (data.file_2) {
            size += data.file_2.size
        }
        if (data.file_3) {
            size += data.file_3.size
        }
        const mb = size / 1024 / 1024
        if (mb > 10) {
            alert(t('file_size_exceeds_5mb'))
            return
        }

        const response = await post('receive', {
            ...data,
            code: 'WrongData',
            tracking_no: shipment.tracking_no
        })
        if (response.data) {
            if (response.data.error) {
                alert(response.data.error)
            }
            if (response.status === 200) {
                onSuccess()
            }
        }
    }

    const handleClose = () => {
        window.location.href = '/'
    }

    const handleChange = (e) => {
        const { name, value } = parseFormEvent(e)
        setData({ ...data, [name]: value })
    }

    return modal2Active ? (
        <>
            <Heading
                text={t('modal_2_title')}
                className="mb-4 m:mb-6"
                size="s"
                component="h2"
            />
            <Message color="violet" className="mb-6 m:mb-8">
                <p>{t('modal_2_infoblock')}</p>
            </Message>
            <Button onClick={handleClose} text={t('modal_2_close')} />
        </>
    ) : (
        <>
            <Heading
                text={t('modal_1_title')}
                className="mb-4 m:mb-6"
                size="s"
                component="h2"
            />
            <Message color="violet" className="mb-4 m:mb-10">
                <p>{t('modal_1_infoblock')}</p>
            </Message>
            <form onSubmit={handleSubmitModal}>
                <DataForm data={data} onChange={handleChange} />
                {/*
                <Col className="flex align-center m:flex-col">
                    <Button
                        text={t('modal_1_related_documents')}
                        variant="secondary"
                    />
                    <TextBlock className="font-light ml-4 opacity-50 m:text-center m:mt-4">
                        <p>{t('modal_1_related_info')}</p>
                    </TextBlock>
                </Col>
                */}
                <Col className="flex m:flex-col align-center mt-7 m:mt-10">
                    <Button text={t('modal_1_send')} />
                    <Button
                        onClick={handleHideModal}
                        className="ml-3 m:ml-0 m:mt-4"
                        text={t('modal_1_cancel')}
                        color="red"
                    />
                </Col>
            </form>
        </>
    )
}
