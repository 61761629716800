import { useLayoutEffect, useState, useEffect } from 'react'

export default function useMobile() {
    const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

    const [mobile, setMobile] = useState(typeof window != 'undefined' && window.innerWidth < 768)

    useIsomorphicLayoutEffect(() => {
        function updateScreen() {
            setMobile(window.innerWidth < 768)
        }
        window.addEventListener('resize', updateScreen)

        return () => window.removeEventListener('resize', updateScreen)
    }, [])
    return { isMobile: mobile }
}
