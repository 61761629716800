const items = [
    {
        question: 'Що таке Deklaruok24.lt?',
        answer: (
            <p>
                Deklaruok24.lt - це проект митного посередника{' '}
                <a target="_blank" href="https://trevio.eu" rel="noreferrer">
                ЗАТ Trevio
                </a>{' '}
                . Співпрацюючи з перевізниками, що працюють у Литві, система Deklaruok24.lt допомагає перевізникам і одержувачам оформляти імпортні декларації на литовській митниці. Для всіх учасників процесу забезпечується автоматизований, ефективний хід процесу від подачі даних про посилку до оформлення декларації для митниці. Наша мета полягає в тому, щоб одержувач уникав проблем і швидко отримав свою посилку.
            </p>
        ),
        active: true
    },
    {
        question: 'Чому потрібно декларувати закуповувані товари?',
        answer: (
            <p>
                З 1 липня 2021 р. усі товари, придбані не в країнах ЄС, як і в усій Європі, від вартості 0,01 євро мають обкладатися ПДВ на імпорт.
            </p>
        )
    },
    {
        question: 'Коли потрібно посилки декларувати для митниці?',
        answer: (
            <p>
                Посилки, які ви відправляєте не з країн ЄС, потрібно декларувати на митниці. Інформацію, коли потрібно декларувати посилки на митниці, отримаєте від свого перевізника або від митного посередника, який доставить вам посилки.
            </p>
        )
    },
    {
        question: 'Як відбувається декларування посилок?',
        answer: (
            <p>
                Перевізники або одержувачі посилок повинні декларувати посилки на митниці, подаючи електронні митні декларації. Система Deklaruok24.lt обробляє дані щодо посилок, подані литовськими перевізниками, інформує вас про податки, що підлягають сплаті, і після того, як ви здійсните їх сплату, декларує посилку на митниці, а також інформує перевізника, що посилку можна доставити до вас.
            </p>
        )
    },
    {
        question: 'Як нараховуються належні до сплати збори та ПДВ?',
        answer: (
            <>
                <p>
                Якщо власна (дійсна) вартість товарів у посилці, тобто скільки сплачено за самі товари, становить суму до 150 євро, податки розраховують таким чином:
                </p>
                <p>
                    • встановлюється митна вартість товару - це власна (дійсна) вартість товару та сума його перевезення, страхування, наприклад, якщо власна вартість товару 10 євро, за його відправлення, страховку сплачено 30 євро, значить, митна вартість товару становить 40 євро;
                </p>
                <p>
                    • від митної вартості товару нараховується ПДВ на імпорт у розмірі 21 відс.
                </p>
                <p>
                    Якщо власна (дійсна) вартість товару становить суму більше 150 євро::
                </p>
                <p>
                    • встановлюється митна вартість товару; <br />
                    • у порядку, встановленому законодавством ЛР, нараховується мито; <br />
                    • якщо товар акцизний, нараховується акцизний податок; <br />
                    • після складання митної вартості, мита та акцизного податку нараховується ПДВ на імпорт 21 відс.
                    <br />
                </p>
                <p>
                    Система Deklaruok24.lt автоматично ідентифікує митну вартість вашої посилки і характер посилки, з огляду на всі витрати, які вказує перевізник, і представляє розверстку всіх платежів у цій системі.
                </p>
                <p>
                    ВАЖЛИВО: Якщо під час покупки ви сплатите ПДВ (таке відбувається під час покупки у великих ел. магазинах, які повідомляють вам про це під час формування замовлення в їхній системі), у такому разі вам не доведеться платити ПДВ на імпорт у Литві.
                </p>
            </>
        )
    },
    {
        question: 'Що таке IOSS і не IOSS?',
        answer: (
            <>
                <p>
                Ця система оподаткування, створена в ЄС спеціально для імпортованих товарів, називається системою єдиного вікна імпорту (IOSS), коли ПДВ сплачується продавцю при купівлі товару в третій країні.
                </p>
                <p>
                    Якщо товар буде придбано з використанням цієї системи, то продавець буде відповідати за сплату ПДВ, застосованого до товару, придбаного покупцем, тобто покупець сплатить ПДВ продавцю під час покупки товару, а продавець повинен буде перевести зібраний податок до тієї країни-члена ЄС, куди відправляється посилка.
                </p>
                <p>
                Якщо товар буде придбано без використання схеми IOSS, для такої покупки може застосовуватися спеціальна процедура декларування та сплати ПДВ на імпорт, коли сплата ПДВ на імпорт здійснюється після прибуття посилки до Литви і після декларування на митниці.
                </p>
            </>
        )
    },
    {
        question:
            'Чи є послуга декларування товарів через цю систему платною?',
        answer: (
            <p>
                Так, послуга платна, і вартість залежить від перевізника, який доставляє посилку. Ціну побачите під час декларування в нашій системі.
            </p>
        )
    },
    {
        question:
            'Скільки часу займає декларування посилки через цю систему?',
        answer: (
            <p>
                Час залежить від того, чи достатньо інформації про посилку і чи не затримує посилку митниця. Якщо інформації достатньо, то за допомогою цієї системи декларування займе кілька хвилин. Якщо інформації не вистачає - залежить від того, як швидко ви надасте інформацію, якої недостатньо, а також від того, чи не затримала митниця вашу посилку (якщо в неї виникли якісь підозри).
            </p>
        )
    },
    {
        question: 'Що буде, якщо я не подам запитуваних документів?',
        answer: (
            <p>
                Якщо від системи Deklaruok24.lt отримаєте повідомлення про те, що необхідно подати додаткові документи, і якщо відмовитеся їх подати або не подасте в прийнятний для митниці час, перевізник поверне посилку відправнику.
            </p>
        )
    },
    {
        question: 'Що буде, якщо я не здійснюватиму сплату зазначених податків?',
        answer: (
            <p>
                Якщо ви не сплатите нараховані платежі у прийнятний для митниці термін, після закінчення терміну зберігання посилка буде повернута відправнику.
            </p>
        )
    },
    {
        question: 'За який термін я повинен сплатити податки за посилку?',
        answer: (
            <p>
                Рекомендуємо зробити це не пізніше ніж протягом 5 днів з дня надходження товару на склад. Оплата впливає на швидкість доставки посилки, тому якщо хочете отримати посилку швидше, рекомендуємо якнайшвидше сплатити і податки. У разі несплати протягом 5 днів з дня надходження товарів на склад, на 6-ий день посилка повертається відправнику. Слід зазначити, що повідомлення про сплату висилається вам ще до того, як товари надійдуть на склад. Зазвичай повідомлення надсилається за 2-3 тижні до надходження на склад.
            </p>
        )
    },
    {
        question:
            'Яка відповідальність загрожує, якщо виправлю вартість посилки, зазначену в системі, на меншу суму?',
        answer: (
            <p>
                За подання в декларації невірних даних може призначатися штраф (передбачено в ст. 211-212 Кодексу адміністративних правопорушень).
            </p>
        )
    },
    {
        question:
            'Чи зможу я повернути податки на імпорт, якщо товар мені не підійде і я захочу повернути його продавцю?',
        answer: (
            <p>
                Так, така можливість є, при заповненні експортної декларації та подачі відповідного прохання до митного органу. Додаткові витрати будуть за послуги митного посередника.
            </p>
        )
    },
    {
        question: 'Чи можу я відмовитися від посилки?',
        answer: (
            <p>
                Так. Від посилки можна відмовитися на першому етапі декларування посилки на Deklaruok24.lt.  (1 крок)
            </p>
        )
    },
    {
        question:
            'Що робити, якщо не відповідають дані, які відображаються при декларуванні посилки?',
        answer: (
            <p>
                Можете надати додаткові дані або виправити дані на першому етапі декларування посилки на Deklaruok24.lt. (1 крок)
            </p>
        )
    },
    {
        question: 'Чи можу я декларувати в іншому місці?',
        answer: (
            <p>
               Так, доки посилка не надійде на склад до розподільчого центру, можете декларувати самі на порталі самообслуговування сайту митниці або через іншого митного посередника. На першому етапі декларування посилки на Deklaruok24.lt є можливість зберегти наявні цифрові дані щодо посилки.
            </p>
        )
    },
    {
        question: 'Куди звертатися, якщо в мене є додаткові запитання?',
        answer: (
            <p>
                З усіх питань звертайтеся на ел. пошту:{' '}
                <a href="mailto:info@deklaruok24.lt">info@deklaruok24.lt</a>.
            </p>
        )
    },
    {
        question:
            'Якщо вартість посилки вказана в іноземній валюті, як її перерахувати в євро при розрахунку податків на імпорт?',
        answer: (
            <>
                <p>
                "Митна вартість імпортованих товарів, які оцінені в іноземній валюті, перераховується в євро за валютним курсом, що діє на день приймання імпортної декларації.
                </p>
                <p>
                Діючі співвідношення євро та інших валют встановлюються, керуючись порядком, встановленим у статті 146 Акта про реалізацію Митного кодексу Союзу. Співвідношення євро та інших валют публікуються на сайті Литовської митниці{' '}
                    <a
                        href="http://www.lrmuitine.lt/web/guest/767"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Литовської митниці
                    </a>
                    .
                </p>
            </>
        )
    }
]

export default items
