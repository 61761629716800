import useTranslation from 'next-translate/useTranslation'
import { Heading, Button } from '@ui'
import { Message } from '@components'
import { post } from '@utils/api'
import useAppState from '@utils/state'

export default function DeclareElsewhere({
    modal42Active,
    setModal42Active,
    handleHideModal
}) {
    const { t } = useTranslation('declare')
    const { shipment, updateShipment } = useAppState()

    const handleConfirm = async () => {
        const { tracking_no } = shipment
        const response = await post('receive', {
            code: 'ReceiverDeclareElsewhere',
            tracking_no
        })
        if (response.data) {
            if (response.data.error) {
                alert(response.data.error)
            }
            if (response.status === 200) {
                setModal42Active(true)
                updateShipment({ ...shipment, elsewhere: true })
            }
        }
    }

    // const handleDownload = (e) => {
    //     e.preventDefault()
    //
    //     const text = [
    //         `${t('shipment_nr')}: ${shipment.tracking_no} / ${
    //             shipment.carrier
    //         }`,
    //         `${t('purchase_account_nr')}: ${shipment.invoice}`,
    //         `${t('date_of_purchase')}: ${shipment.date}`
    //     ].join('\r\n')
    //
    //     const filename = 'details.txt'
    //
    //     var element = document.createElement('a')
    //     element.setAttribute(
    //         'href',
    //         'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
    //     )
    //     element.setAttribute('download', filename)
    //     element.style.display = 'none'
    //     document.body.appendChild(element)
    //     element.click()
    //     document.body.removeChild(element)
    // }

    const downloadUrl = `${process.env.NEXT_PUBLIC_API_URL}shipment-details/${shipment.secret}`

    return (
        <>
            <Heading
                text={'Deklaravimas kitur'}
                className="mb-4 m:mb-6"
                size="s"
                component="h2"
            />
            {modal42Active ? (
                <>
                    <Message color="violet" className="mb-6 m:mb-8">
                        <p>
                            {t('declare_elsewhere_confirmation_text_1')}
                            <a
                                href={downloadUrl}
                                download
                                target="_blank"
                                rel="noreferrer"
                            >
                                {t('declare_elsewhere_confirmation_text_2')}
                            </a>
                            {t('declare_elsewhere_confirmation_text_3')}
                        </p>
                    </Message>
                    <div className="btnGroup">
                        <Button onClick={handleHideModal} text={t('close')} />
                    </div>
                </>
            ) : (
                <>
                    <Message color="violet" className="mb-6 m:mb-8">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: t('declare_elsewhere_text_1')
                            }}
                        />
                        <a
                            href={downloadUrl}
                            download
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t('declare_elsewhere_text_2')}
                        </a>
                    </Message>
                    <div className="btnGroup">
                        <Button
                            onClick={handleConfirm}
                            text={t('declare_elsewhere_confirm')}
                        />
                        <Button
                            color="red"
                            onClick={handleHideModal}
                            text={t('declare_elsewhere_cancel')}
                        />
                    </div>
                </>
            )}
        </>
    )
}
