import { createElement } from 'react'
import PropTypes from 'prop-types'
import cx from '@utils/classNames'

const Heading = ({ size, component, text, color, className, ...rest }) => {
    return createElement(
        component,
        {
            className: cx('Heading', `Heading--${size}`, `text-${color}`, className),
            ...rest
        },
        text
    )
}

Heading.propTypes = {
    size: PropTypes.oneOf(['l', 'm', 's', 'xs', 'xxs']),
    component: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4']),
    color: PropTypes.oneOf(['black', 'white']),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    className: PropTypes.string
}

Heading.defaultProps = {
    component: 'h2',
    color: 'black',
    size: 'm'
}

export default Heading
