import { Heading } from '@ui'
import { ReactComponent as Step1Icon } from '../assets/icons/step-1-success.svg'
import useMobile from '@utils/useMobile'
import useTranslation from 'next-translate/useTranslation'

function Success() {
    const { t } = useTranslation('declare')
    const { isMobile } = useMobile()

    return (
        <Heading
            text={
                <span className="inline-flex align-center">
                    <Step1Icon
                        width={isMobile ? '3.1rem' : '4.2rem'}
                        className="mr-3 m:mr-5"
                    />
                    {t('success_title')}
                </span>
            }
            className="mb-3 m:mb-3"
            size="s"
            component="h2"
        />
    )
}

export default Success
