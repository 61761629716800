import { useState } from 'react'
import PropTypes from 'prop-types'
import cx from '@utils/classNames'

const TextArea = ({ label, className, textarea }) => {
    const [active, setActive] = useState(false)

    const handleBlur = e => {
        if (!e.currentTarget.value) setActive(false)
    }

    return (
        <div className={cx('Form__control', active && 'isActive', className)}>
            <textarea
                onFocus={() => setActive(true)}
                onBlur={handleBlur}
                className="Form__textarea text-black bg-white"
                {...textarea}
            />
            {label && <label className="Form__label text-grey-dark">{label?.text}</label>}
        </div>
    )
}

TextArea.propTypes = {
    textarea: PropTypes.shape({}),
    label: PropTypes.shape({
        text: PropTypes.node
    }),
    className: PropTypes.string
}

export default TextArea
