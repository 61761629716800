import useTranslation from 'next-translate/useTranslation'
import { Heading, Button } from '@ui'
import { Message } from '@components'
import { post } from '@utils/api'
import useAppState from '@utils/state'
import { useRouter } from 'next/router'

export default function ShipmentCancel({
    modal32Active,
    setModal32Active,
    setModal3Active
}) {
    const { t } = useTranslation('declare')
    const { shipment, updateShipment } = useAppState()
    const router = useRouter()

    const handleConfirm = async () => {
        const { tracking_no } = shipment
        const response = await post('receive', {
            code: 'ReceiverCancel',
            tracking_no
        })
        if (response.data) {
            if (response.data.error) {
                alert(response.data.error)
            }
            if (response.status === 200) {
                setModal32Active(true)
                updateShipment({ ...shipment, canceled: true })
            }
        }
    }

    const handleClose = () => {
        window.location.href = '/'
    }

    return (
        <>
            <Heading
                text={t('refuse_shipment')}
                className="mb-4 m:mb-6"
                size="s"
                component="h2"
            />
            {modal32Active ? (
                <>
                    <Message color="violet" className="mb-6 m:mb-8">
                        <p>{t('shipment_cancelation_success_message')}</p>
                    </Message>
                    <div className="btnGroup">
                        <Button onClick={handleClose} text={t('close')} />
                    </div>
                </>
            ) : (
                <>
                    <Message color="violet" className="mb-6 m:mb-8">
                        <p>{t('shipment_cancelation_text')}</p>
                    </Message>
                    <div className="btnGroup">
                        <Button
                            onClick={handleConfirm}
                            text={t('shipment_cancelation_confirm')}
                        />
                        <Button
                            color="red"
                            onClick={() => setModal3Active(false)}
                            text={t('shipment_cancelation_cancel')}
                        />
                    </div>
                </>
            )}
        </>
    )
}
