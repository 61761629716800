export default function parseFormEvent(e) {
    if (e.name && e.value) {
        return e
    }

    let name, value
    const target = e.target
    const type = target.getAttribute('type')

    name = target.name
    if (type === 'file') {
        value = target.files[0]
    } else if (type === 'checkbox') {
        value = target.checked ? 1 : 0
    } else {
        value = target.value
    }

    return { name, value }
}
