import useTranslation from 'next-translate/useTranslation'
import { useState } from 'react'
import { post } from 'utils/api'
import {
    Grid,
    Col,
    TextBlock,
    Link,
    Heading,
    Input,
    TextArea,
    Checkbox,
    Button
} from '@ui'

const ContactForm = () => {
    const { t } = useTranslation('contacts')
    const [sent, setSent] = useState(false)
    const [state, setState] = useState({
        name: '',
        email: '',
        phone: '',
        shipment_nr: '',
        text: ''
    })

    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setState({ ...state, [name]: value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const response = await post('contact', state)
        if (response.status === 200) {
            setSent(true)
        }
    }

    return (
        <Grid className="ContactForm">
            <Col className="ContactForm__left shadow-2 m:shadow-none bg-white">
                <div
                    className="ContactForm__image"
                    style={{ backgroundImage: 'url(/media/contact@2x.png)' }}
                ></div>
                {sent ? (
                    <div className="ContactForm__form m:mb-9 sent">
                        <Heading
                            text={t('success_title')}
                            className="mb-2 m:mb-3"
                            size="xs"
                            component="h2"
                        />
                        <p>{t('success_text')}</p>
                    </div>
                ) : (
                    <form
                        onSubmit={handleSubmit}
                        className="ContactForm__form m:mb-9"
                    >
                        <Heading
                            text={t('form_title')}
                            className="mb-2 m:mb-3"
                            size="xs"
                            component="h2"
                        />
                        <Input
                            className="mb-2"
                            label={{ text: t('name') }}
                            input={{
                                name: 'name',
                                type: 'text',
                                value: state.name,
                                onChange: handleChange,
                                required: 1
                            }}
                        />
                        <Input
                            className="mb-2"
                            label={{ text: t('email') }}
                            input={{
                                name: 'email',
                                type: 'email',
                                value: state.email,
                                onChange: handleChange,
                                required: 1
                            }}
                        />
                        <Input
                            className="mb-2"
                            label={{ text: t('phone') }}
                            input={{
                                name: 'phone',
                                type: 'text',
                                onChange: handleChange,
                                value: state.phone,
                                onChange: handleChange
                            }}
                        />
                        <Input
                            className="mb-2"
                            label={{ text: t('shipment_nr') }}
                            input={{
                                name: 'shipment_nr',
                                type: 'text',
                                value: state.shipment_nr,
                                onChange: handleChange,
                                required: 1
                            }}
                        />
                        <TextArea
                            className="mb-5 m:mb-9"
                            label={{ text: t('message') }}
                            textarea={{
                                name: 'text',
                                onChange: handleChange,
                                value: state.text,
                                required: 1
                            }}
                        />
                        <Col className="mb-5 m:mb-6">
                            <Checkbox
                                checkbox={{ required: 1 }}
                                label={{
                                    text: (
                                        <>
                                            {t('agreement')} &nbsp;
                                            <Link
                                                to="/"
                                                text={t('privacy_policy')}
                                            />
                                            .
                                        </>
                                    )
                                }}
                            />
                        </Col>
                        <Button text={t('send')} type="sbmit" />
                    </form>
                )}
            </Col>
            {/*<Col className="ContactForm__right">
                <TextBlock className="font-light mb-9 m:mb-6  mt-7 ">
                    <p>
                        <strong>{t('block_1_title')}</strong>
                        <br />
                        {t('block_1_phone')}
                        <br />
                        <Link href={`mailto:${t('block_1_email')}`} text={t('block_1_email')} />
                    </p>
                </TextBlock>
                <TextBlock className="font-light mb-9 m:mb-0">
                    <p>
                        <strong>{t('block_2_title')}</strong>
                        <br />
                        {t('block_2_phone')}
                        <br />
                        <Link href={`mailto:${t('block_2_email')}`} text={t('block_2_email')} />
                    </p>
                </TextBlock>
                <TextBlock className="font-light mb-9 m:mb-6 m:mt-14 m:pl-11">
                    <p>
                        <strong>{t('block_3_title')}</strong>
                        <br />
                        {t('block_3_time')}
                    </p>
                </TextBlock>
                <TextBlock className="font-light mb-5 m:mb-0 m:pl-11">
                    <p>
                        <strong>{t('block_4_title')}</strong>
                        <br />
                        {t('block_4_text_1')}
                        <br />
                        {t('block_4_text_2')}
                    </p>
                </TextBlock>
            </Col>*/}
        </Grid>
    )
}

export default ContactForm
