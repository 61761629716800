const items = [
    {
        question: 'Что такое Deklaruok24.lt?',
        answer: (
            <p>
                Deklaruok24.lt – это проект таможенного посредника {' '}
                <a target="_blank" href="https://trevio.eu" rel="noreferrer">
                ЗАО Trevio
                </a>{' '}. Сотрудничая с перевозчиками, работающими в Литве, система Deklaruok24.lt помогает перевозчикам и получателям оформлять импортные декларации на литовской таможне. Для всех участников процесса обеспечивается автоматизированный, эффективный ход процесса от подачи данных по посылке до оформления декларации для таможни. Наша цель состоит в том, чтобы получатель избежал проблем и быстро получил свою посылку.
            </p>
        ),
        active: true
    },
    {
        question: 'Почему нужно декларировать приобретаемые товары?',
        answer: (
            <p>
                С 1 июля 2021 г. все товары, приобретаемые не в странах ЕС, как и по все Европе, от стоимости 0,01 евро должны облагаться НДС на импорт.
            </p>
        )
    },
    {
        question: 'Когда нужно посылки декларировать для таможни?',
        answer: (
            <p>
                Посылки, которые вы отправляете не из стран ЕС, нужно декларировать на таможне. Информацию, когда нужно декларировать посылки на таможне, получите от своего перевозчика или от таможенного посредника, который доставит вам посылки.
            </p>
        )
    },
    {
        question: 'Как происходит декларирование посылок?',
        answer: (
            <p>
                Перевозчики или получатели посылок должны декларировать посылки на таможне, подавая электронные таможенные декларации. Система Deklaruok24.lt обрабатывает данные по посылкам, представленные литовскими перевозчиками, информирует вас о подлежащих уплате налогах и после того, как вы произведете их уплату, декларирует посылку на таможне, а также информирует перевозчика, что посылку можно доставить к вам.
            </p>
        )
    },
    {
        question: 'Как начисляются подлежащие уплате сборы и НДС?',
        answer: (
            <>
                <p>
                Если собственная (действительная) стоимость товаров в посылке, т.е. сколько уплачено за сами товары, составляет сумму до 150 евро, налоги рассчитываются таким образом:
                </p>
                <p>
                • устанавливается таможенная стоимость товара – это собственная (действительная) стоимость товара и сумма ее перевозки, страхования, например, если собственная стоимость товара 10 евро, за его отправку, страховку уплачено 30 евро, значит, таможенная стоимость товара составляет 40 евро;<br/>
                • от таможенной стоимости товара начисляется НДС на импорт в размере 21 проц.
                </p>
                <p>
                Если собственная (действительная) стоимость товара составляет сумму больше 150 евро:
                </p>
                <p>
                • устанавливается таможенная стоимость товара; <br/>
                • в порядке, установленном законодательством ЛР, начисляется таможенная пошлина; <br/>
                • если товар акцизный, начисляется акцизный налог; <br/>
                • после сложения таможенной стоимости, таможенной пошлины и акцизного налога начисляется НДС на импорт 21 проц.<br/>
                </p>
                <p>
                Система Deklaruok24.lt автоматически идентифицирует таможенную стоимость вашей посылки и характер посылки, учитывая все расходы, которые указывает перевозчик, и представляет разверстку всех платежей в этой системе.
                </p>
                <p>
                ВАЖНО: Если во время покупки вы уплатите НДС (такое происходит при покупке в крупных эл. магазинах, которые сообщают вам об этом при формировании заказа в их системе), в таком случае вам не придется платить НДС на импорт в Литве.
                </p>
            </>
        )
    },
    {
        question: 'Что такое IOSS и не IOSS?',
        answer: (
            <>
                <p>
                Эта система налогообложения, созданная в ЕС специально для импортируемых товаров, называется системой единого окна импорта (IOSS), когда НДС уплачивается продавцу при покупке товара в третьей стране.
                </p>
                 <p>
                 Если товар будет приобретен с использованием этой системы – то продавец будет отвечать за уплату НДС, применяемого к товару, приобретенному покупателем, т.е. покупатель уплатит НДС продавцу при покупке товара, а продавец должен будет перевести собранный налог в ту страну-член ЕС, куда отправляется посылка.
                 </p>
                 <p>
                 Если товар будет приобретен без использования схемы IOSS, для такой покупки может применяться специальная процедура декларирования и уплаты НДС на импорт, когда уплата НДС на импорт производится после прибытия посылки в Литву и после декларирования на таможне.
                </p>
            </>
        )
    },
    {
        question: 'Является ли услуга декларирования товаров через эту систему платной?',
        answer: (
            <p>
                Да, услуга платная, и стоимость зависит от перевозчика, который доставляет посылку. Цену увидите во время декларирования в нашей системе.
            </p>
        )
    },
    {
        question: 'Сколько времени занимает декларирование посылки через эту систему?',
        answer: (
            <p>
                Время зависит от того, достаточно ли информации о посылке и не задерживает ли посылку таможня. Если информации достаточно, то с помощью этой системы декларирование займет несколько минут. Если информации не хватает – зависит от того, как быстро вы представите недостающую информацию, а также от того – не задержала ли таможня вашу посылку (если у нее возникли какие-либо подозрения).
            </p>
        )
    },
    {
        question: 'Что будет, если я не представлю запрашиваемых документов?',
        answer: (
            <p>
                Если от системы Deklaruok24.lt получите уведомление о том, что необходимо представить дополнительные документы, и если откажетесь их представить или не представите в приемлемое для таможни время, перевозчик вернет посылку отправителю.
            </p>
        )
    },
    {
        question: 'Что будет, если я не произведу уплату указанных налогов?',
        answer: (
            <p>
                Если вы не уплатите начисленные платежи за приемлемый для таможни срок, по окончании срока хранения посылка будет возвращена отправителю.
            </p>
        )
    },
    {
        question: 'За какой срок я должен уплатить налоги за посылку?',
        answer: (
            <p>
                Рекомендуем сделать это не позднее чем в течение 5 дней со дня поступления товара на склад. Оплата влияет на скорость доставки посылки, поэтому если хотите получить посылку быстрее, рекомендуем поскорее уплатить и налоги. В случае неуплаты в течение 5 дней со дня поступления товаров на склад, на 6-ой день посылка возвращается отправителю. Следует отметить, что уведомление об уплате высылается вам еще до того, как товары поступят на склад. Обычно уведомление высылается за 2-3 нед. до поступления на склад.
            </p>
        )
    },
    {
        question:
            'Какая ответственность грозит, если исправлю стоимость посылки, указанную в системе, на меньшую сумму?',
        answer: (
            <p>
                За представление в декларации неверных данных может назначаться штраф (предусмотрено в ст. 211-212 Кодекса административных правонарушений).
            </p>
        )
    },
    {
        question:
            'Смогу ли я вернуть налоги на импорт, если товар мне не подойдет и я захочу вернуть его продавцу?',
        answer: (
            <p>
                Да, такая возможность есть, при заполнении экспортной декларации и подачи соответствующего прошения в таможенный орган. Дополнительные расходы будут за услуги таможенного посредника.
            </p>
        )
    },
    {
        question: 'Могу ли я отказаться от посылки?',
        answer: (
            <p>
                "Да. От посылки можно отказаться на первом этапе декларирования посылки на Deklaruok24.lt. 
 (1 шаг)"
            </p>
        )
    },
    {
        question:
            'Что делать, если не соответствуют данные, отображаемые при декларировании посылки?',
        answer: (
            <p>
                Можете представить дополнительные данные или исправить данные на первом этапе декларирования посылки на Deklaruok24.lt. (1 шаг)
            </p>
        )
    },
    {
        question: 'Могу ли я декларировать в другом месте?',
        answer: (
            <p>
                Да, пока посылка не поступит на склад в распределительный центр, можете декларировать сами на портале самообслуживания сайта таможни или через другого таможенного посредника. На первом этапе декларирования посылки на Deklaruok24.lt есть возможность сохранить имеющиеся цифровые данные по посылке.
            </p>
        )
    },
    {
        question: 'Куда обращаться, если у меня есть дополнительные вопросы?',
        answer: (
            <p>
                По всем вопросам обращайтесь по эл. почте: {' '}
                <a href="mailto:info@deklaruok24.lt">info@deklaruok24.lt</a>.
            </p>
        )
    },
    {
        question:
            'Если стоимость посылки указана в иностранной валюте, как ее пересчитать в евро при расчете налогов на импорт?',
        answer: (
            <>
            <p>Таможенная стоимость импортируемых товаров, которые оценены в иностранной валюте, пересчитывается в евро по валютному курсу, действующему на день приема импортной декларации.
            </p>
            <p>
            Действующие соотношения евро и других валют устанавливаются, руководствуясь порядком, установленным в статье 146 Акта о реализации Таможенного кодекса Союза. Соотношения евро и других валют публикуются на сайте {' '}
                    <a
                        href="http://www.lrmuitine.lt/web/guest/767"
                        target="_blank"
                        rel="noreferrer"
                    >Литовской таможни</a>.
            </p>
            </>
        )
    }
]

export default items
