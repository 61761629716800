
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import '@stripe/stripe-js'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { StateContextProvider } from '@utils/state'
import { Layout } from '@components'
import useTranslation from 'next-translate/useTranslation'

import '../styles/globals.scss'

function MyApp({ Component, pageProps }) {
    const site = 'https://deklaruok24.lt'
    const { t } = useTranslation('common')
    const canonicalURL = site + useRouter().pathname
    const title = t('meta_title')
    const description = t('meta_description')
    const image = '/social.jpg'

    return (
        <StateContextProvider>
            <Layout>
                <Head>
                    <link rel="canonical" href={canonicalURL} />
                    <meta property="og:url" content={canonicalURL} />
                    <meta
                        httpEquiv="Content-Type"
                        content="text/html; charset=utf-8"
                    />
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0"
                    />
                    <meta
                        name="verify-neopay"
                        content="8899e7dbddb8df0385af5c22b35c4df0"
                    />
                    <meta property="og:type" content="website" />
                    <title>{title}</title>
                    <meta name="robots" content="noindex, nofollow" />
                    <meta name="title" content={title} />
                    <meta itemProp="name" content={title} />
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={description} />
                    <meta itemProp="description" content={description} />
                    <meta name="description" content={description} />
                    <meta itemProp="image" content={image} />
                    <meta property="og:image" content={image} />
                    <meta name="twitter:image" content={image} />
                    <meta name="apple-mobile-web-app-capable" content="yes" />
                    <meta name="msapplication-TileColor" content="#f8f8f8" />
                    <meta name="theme-color" content="#f8f8f8" />
                </Head>
                <Component {...pageProps} />
                <div id="modal" />
            </Layout>
        </StateContextProvider>
    )
}

const __Page_Next_Translate__ = MyApp


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  