import useTranslation from 'next-translate/useTranslation'
import { TextBlock, TextArea, Input } from '@ui'
import { useState } from 'react'

export default function DataForm({ data, onChange, conditions }) {
    const { t } = useTranslation('declare')
    // t('modal_1_your_comments')
    const accept = 'image/png,image/jpeg,image/jpg,application/pdf'

    let dataConditions = {
        text: {
            required: true,
            show: true,
            label: t('your_comments')
        },
        file_1: {
            required: false,
            show: true,
            label: `${t('related_documents')} 1`
        },
        file_2: {
            required: false,
            show: true,
            label: `${t('related_documents')} 2`
        },
        file_3: {
            required: false,
            show: true,
            label: `${t('related_documents')} 3`
        }
    }
    if (conditions) {
        dataConditions = conditions
    }

    return (
        <>
            <TextArea
                className="mb-5 m:mb-10"
                label={{ text: dataConditions.text.label }}
                textarea={{
                    name: 'text',
                    required: dataConditions.text.required,
                    value: data.text,
                    onChange
                }}
            />
            {dataConditions.file_1.show && (
                <Input
                    className="mb-2"
                    label={{ text: dataConditions.file_1.label }}
                    icon="file"
                    input={{
                        accept,
                        name: 'file_1',
                        type: 'file',
                        value: data.file_1,
                        required: dataConditions.file_1.required,
                        onChange
                    }}
                />
            )}
            {dataConditions.file_2.show && (
                <Input
                    className="mb-2"
                    label={{ text: dataConditions.file_2.label }}
                    icon="file"
                    input={{
                        accept,
                        name: 'file_2',
                        type: 'file',
                        value: data.file_2,
                        required: dataConditions.file_2.required,
                        onChange
                    }}
                />
            )}
            {dataConditions.file_3.show && (
                <Input
                    className="mb-2"
                    label={{ text: dataConditions.file_3.label }}
                    icon="file"
                    input={{
                        accept,
                        name: 'file_3',
                        type: 'file',
                        value: data.file_3,
                        required: dataConditions.file_3.required,
                        onChange
                    }}
                />
            )}
            <TextBlock className="mb-6 m:mb-12">
                <small>{t('files_requirements')}</small>
            </TextBlock>
        </>
    )
}
