import useTranslation from 'next-translate/useTranslation'
import { Heading, TextBlock } from '@ui'
import ListItem from './ListItem'

const HowTo = ({ value }) => {
    const { t } = useTranslation('home')

    const listItems = [
        {
            image: { src: 'media/how-to-step-1.svg', alt: '' },
            title: t('steps_1')
        },
        {
            image: { src: 'media/how-to-step-2.svg', alt: '' },
            title: t('steps_2')
        },
        {
            image: { src: 'media/how-to-step-3.svg', alt: '' },
            title: t('steps_3')
        },
        {
            image: { src: 'media/how-to-step-4.svg', alt: '' },
            title: t('steps_4')
        },
        {
            image: { src: 'media/how-to-step-5.svg', alt: '' },
            title: t('steps_5')
        }
    ]

    if (value === 2) {
        return (
            <TextBlock>
                <br />
                <p>{t('how_to_for_confirmation')}</p>
            </TextBlock>
        )
    }

    return (
        <>
            <Heading text={t('steps_title')} className="mb-6" />
            <ul className="HowTo">
                {listItems.map((item, index) => (
                    <ListItem key={index} number={index} {...item} />
                ))}
            </ul>
        </>
    )
}

export default HowTo
