import useTranslation from 'next-translate/useTranslation'
import { useEffect, useState } from 'react'
import cx from '@utils/classNames'
import useMobile from '@utils/useMobile'
import { Section, Grid, Col, Container, Heading, Main } from '@ui'
import { SideNav } from '@components'
import SingletonRouter, { Router, useRouter } from 'next/router'
import useAppState from '@utils/state'
import { ReactComponent as InfoIcon } from '../assets/icons/icon-i.svg'

function Declare({
    children,
    minimal,
    title,
    mobileHideSteps,
    disabled,
    allowLeave
}) {
    const { t } = useTranslation('declare')
    const { isMobile } = useMobile()
    const router = useRouter()
    const message = t('confirm_leave')
    const { shipment, setShowHowTo } = useAppState()

    useEffect(() => {
        if (shipment && !shipment.confirmed && router.pathname !== '/declare') {
            router.push('/declare')
        }
    }, [shipment])

    useEffect(() => {
        if (allowLeave) return

        SingletonRouter.router.change = (...args) => {
            const when =
                args[1].indexOf('declare') < 0 &&
                args[1].indexOf('extra-data') < 0 &&
                args[1].indexOf('missing-data') < 0
            if (!when)
                return Router.prototype.change.apply(
                    SingletonRouter.router,
                    args
                )
            return confirm(message)
                ? Router.prototype.change.apply(SingletonRouter.router, args)
                : new Promise((resolve, reject) => resolve(false))
        }
        return () => {
            delete SingletonRouter.router.change
        }
    }, [allowLeave])

    const excludeNav = ['/declare', '/declare/error', '/declare/success']
    const title1 = title ? title : t('sidebar_title_mobile')
    const title2 = title ? title : t('sidebar_title')

    return (
        <Main className="bg-sand-light m:bg-white">
            <Section>
                <Container className="pt-13 pb-20 m:pt-19 m:pb-0">
                    <Grid className="mb-3 m:pb-10">
                        <Col
                            size="25%"
                            className={cx(
                                'pr-5 m:px-6 m:py-7 m:bg-sand-light m:-mx-6 m:w-auto',
                                excludeNav.includes(router.pathname) &&
                                    'm:hidden'
                            )}
                        >
                            <div
                                className={
                                    minimal ? 'small-minimal-heading' : ''
                                }
                            >
                                {isMobile ? (
                                    <Heading
                                        text={
                                            <>
                                                {title1}
                                                <InfoIcon
                                                    className="headerInfo"
                                                    onClick={() =>
                                                        setShowHowTo(
                                                            title ? 2 : 1
                                                        )
                                                    }
                                                    width="2rem"
                                                    height="2rem"
                                                />
                                            </>
                                        }
                                        className="m:mb-4"
                                        size="m"
                                        component="h1"
                                    />
                                ) : (
                                    <Heading
                                        text={
                                            <>
                                                {title2}
                                                <InfoIcon
                                                    className="headerInfo"
                                                    onClick={() =>
                                                        setShowHowTo(
                                                            title ? 2 : 1
                                                        )
                                                    }
                                                    width="2rem"
                                                    height="2rem"
                                                />
                                            </>
                                        }
                                        className="mb-6"
                                        size="s"
                                        component="h1"
                                    />
                                )}

                                {!minimal && <SideNav disabled={disabled} />}
                            </div>
                        </Col>
                        <Col size="75%">{children}</Col>
                    </Grid>
                </Container>
            </Section>
        </Main>
    )
}

export default Declare
