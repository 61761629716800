const items = [
    {
        question: 'Kas yra Deklaruok24.lt?',
        answer: (
            <p>
                Deklaruok24.lt yra muitinės tarpininko{' '}
                <a target="_blank" href="https://trevio.eu" rel="noreferrer">
                    UAB "Trevio"
                </a>{' '}
                projektas. Bendradarbiaujant su Lietuvoje veikiančiais vežėjais,
                Deklaruok24.lt sistema padeda vežėjams ir gavėjams įforminti
                importo deklaracijas Lietuvos muitinėje. Visiems proceso
                dalyviams užtikrinama automatizuota, efektyvi proceso eiga nuo
                siuntos duomenų pateikimo iki deklaracijos įforminimo muitinei.
                Mūsų tikslas, kad gavėjas nepatirtų rūpesčių ir savo siuntą
                gautų greitai.
            </p>
        ),
        active: true
    },
    {
        question: 'Kodėl reikia deklaruoti įsigijamas prekes?',
        answer: (
            <p>
                Nuo 2021m. liepos 1 dienos visos įsigyjamos iš ne ES šalių
                prekės, kaip ir visoje Europoje, nuo 0,01 euro vertės turi būti
                apmokestintos importo PVM.
            </p>
        )
    },
    {
        question: 'Kada reikia siuntas deklaruoti muitinei?',
        answer: (
            <p>
                Siuntos, kurias siunčiatės iš ne ES šalių, reikia deklaruoti
                muitinėje. Informaciją, kada reikia deklaruoti siuntas
                muitinėje, gausite iš savo vežėjo arba jo muitinės tarpininko,
                kuris pristatys Jums siuntas.
            </p>
        )
    },
    {
        question: 'Kaip vyksta siuntų deklaravimas?',
        answer: (
            <p>
                Siuntų vežėjai arba gavėjai privalo deklaruoti siuntas
                muitinėje, pateikdami elektronines muitinės deklaracijas.
                Deklaruok24.lt sistema apdoroja Lietuvos vežėjų pateiktus siuntų
                duomenis, informuoja Jus apie mokėtinus mokesčius, ir, Jums juos
                sumokėjus, deklaruoja siuntą muitinėje, bei informuoja vežėją,
                kad siuntą galima Jums pristatyti.
            </p>
        )
    },
    {
        question: 'Kaip apskaičiuojami mokėtini mokesčiai ir PVM?',
        answer: (
            <>
                <p>
                    Jei prekių siuntoje savoji (tikroji) vertė, t. y. kiek
                    sumokėta už pačias prekes, yra iki <strong>150 eurų</strong>
                    , mokesčiai skaičiuojami taip:
                </p>
                <p>
                    • nustatoma prekės muitinė vertė – tai prekės savosios
                    (tikrosios) vertės ir jos gabenimo, draudimo suma, pvz., jei
                    prekės savoji vertė yra 10 eurų, už jos siuntimą, draudimą
                    sumokėta 30 eurų, vadinasi, prekės muitinė vertė yra 40
                    eurų;
                    <br />• nuo prekės muitinės vertės apskaičiuojamas 21 proc.
                    importo PVM.
                </p>
                <p>
                    <strong>
                        Jei prekės savoji (tikroji) vertė yra daugiau kaip 150
                        EUR:
                    </strong>
                </p>
                <p>
                    • nustatoma prekės muitinė vertė; • apskaičiuojamas
                    nustatytas LR įstatymų tvarka muito mokestis; • jei prekė
                    akcizinė, apskaičiuojamas akcizo mokestis; • sudėjus prekės
                    muitinę vertę, muito ir akcizo mokesčius, apskaičiuojamas 21
                    proc. importo PVM.
                </p>
                <p>
                    Deklaruok24.lt sistema automatiniu būdu identifikuoja Jūsų
                    siuntos muitinę vertę bei siuntos pobūdį, įvertindama visas
                    išlaidas, kurias pateikia vežėjas, ir pateikia šių mokesčių
                    išklotinę šioje sistemoje.
                </p>
                <p>
                    <strong>SVARBU:</strong> Jei pirkimo metu Jūs sumokėsite PVM
                    (taip yra perkant iš didžiųjų e-parduotuvių, kurios apie tai
                    praneša Jums formuojant užsakymą jų sistemoje), tokiu atveju
                    importo PVM Lietuvoje Jums nereikės mokėti.
                </p>
            </>
        )
    },
    {
        question: 'Kas yra IOSS ir ne IOSS?',
        answer: (
            <>
                <p>
                    Tai ES sukurta speciali importuojamoms prekėms apmokestinimo
                    schema, vadinama Importo vieno langelio Sistema (IOSS), kai
                    PVM sumokamas pardavėjui prekę perkant trečiojoje šalyje.
                </p>
                <p>
                    Jei prekė bus įsigyta naudojantis šia sistema – tai
                    pardavėjas bus atsakingas už pirkėjo įsigytai prekei taikomo
                    PVM sumokėjimą, t. y. PVM pirkėjas sumokės pardavėjui
                    pirkdamas prekę, o pardavėjas turės surinktą mokestį
                    pervesti tai ES valstybei narei, į kurią siunčiama siunta.
                </p>
                <p>
                    Jei prekė bus įsigyta nesinaudojant IOSS schema, tokiam
                    pirkimui gali būti taikoma speciali importo PVM deklaravimo
                    ir mokėjimo procedūra, importo PVM sumokant prekę
                    atsisiuntus į Lietuvą ir deklaravus muitinėje.
                </p>
            </>
        )
    },
    {
        question: 'Ar prekių deklaravimo paslauga per šią sistemą yra mokama?',
        answer: (
            <p>
                Taip, paslauga yra mokama, ir kaina priklauso nuo vežėjo, kuris
                pristato siuntą. Kainą matysite mūsų sistemoje deklaravimo metu.
            </p>
        )
    },
    {
        question: 'Kiek laiko užtrunka siuntų deklaravimas per šią sistemą?',
        answer: (
            <p>
                Laikas priklauso nuo to, ar netrūksta informacijos apie siuntą
                ir ar muitinė nesulaiko siuntos. Jei netrūksta, tai naudojantis
                šia sistema, deklavimas įvyksta per kelias minutes. Jei trūksta
                – priklauso nuo to, kaip greitai Jūs pateiksite trūkstamą
                informaciją, o taip pat nuo to – ar muitinė nesulaikė Jūsų
                siuntos (jeigu jai kyla įtarimų).
            </p>
        )
    },
    {
        question: 'Kas bus, jeigu nepateiksiu prašomų dokumentų?',
        answer: (
            <p>
                Jei iš Deklaruok24.lt sistemos gausite pranešimą pateikti
                papildomus dokumentus, ir jei atsisakysite juos pateikti arba
                nepateiksite per muitinei priimtiną laiką, vežėjas siuntą
                grąžins siuntėjui.
            </p>
        )
    },
    {
        question: 'Kas bus, jeigu nesumokėsiu nurodytų mokesčių?',
        answer: (
            <p>
                Jei nesumokėsite apskaičiuotų mokesčių per muitinei priimtiną
                laiką, pasibaigus jos saugojimo terminui, siunta bus grąžinta
                siuntėjui.
            </p>
        )
    },
    {
        question: 'Per kiek laiko turiu sumokėti mokesčius už siuntinį?',
        answer: (
            <p>
                Rekomenduojame tai atlikti ne vėliau negu per 5 dienas nuo
                prekės atvykimo į sandėlį. Apmokėjimas įtakoja siuntos
                pristatymo greitį, todėl jei siuntą norite gauti greičiau,
                rekomenduojame greičiau sumokėti ir mokesčius. Nesumokėjus per 5
                dienas nuo prekių atvykimo į sandėlį, 6-tą dieną siunta yra
                gražinama siuntėjui. Pažymėtina, kad jums pranešimas apie
                apmokėjimą išsiunčiamas dar prekėms nesant atvykus į sandėlį.
                Įprastai, pranešimas išsiunčiamas 2-3 sav. iki atvykimo į
                sandėlį.
            </p>
        )
    },
    {
        question:
            'Kokia atsakomybė gresia, jei koreguosiu sistemoje nurodytą siuntos kainą į mažesnę?',
        answer: (
            <p>
                Už neteisingų duomenų deklaracijose pateikimą gali būti skirta
                bauda (numatyta Administracinių nusižengimų kodekso 211-212
                str.).
            </p>
        )
    },
    {
        question:
            'Ar galėsiu susigrąžinti sumokėtus importo mokesčius, jei prekė man netiks ir norėsiu ją grąžinti pardavėjui?',
        answer: (
            <p>
                Taip, tokia galimybė yra, užpildant eksporto deklaraciją bei
                pateikiant atitinkamą prašymą muitinei. Papildomos išlaidos bus
                už muitinės tarpininko paslaugą.
            </p>
        )
    },
    {
        question: 'Ar galiu atsisakyti siuntos?',
        answer: (
            <p>
                Taip. Siuntos galima atsisakyti pirmame Deklaruok24.lt siuntos
                deklaravimo žingsnyje.
            </p>
        )
    },
    {
        question:
            'Jeigu neatitinka duomenys, matomi deklaruojant siuntą - ką daryti?',
        answer: (
            <p>
                Galite pateikti papildomus duomenis arba duomenų korekciją
                pirmame Deklaruok24.lt siuntos deklaravimo žingsnyje.
            </p>
        )
    },
    {
        question: 'Ar galiu deklaruoti kitur?',
        answer: (
            <p>
                Taip, kol siunta neatvyko į siuntų paskirstymo sandėlį, galite
                deklaruoti pats muitinės savitarnos portale arba per kitą
                muitinės tarpininką. Deklaruok24.lt siuntos deklaravimo
                pirmąjame žingsnyje yra galimybė išsisaugoti turimus
                skaitmeninius siuntos duomenis.
            </p>
        )
    },
    {
        question: 'Kur kreiptis, jeigu turiu papildomų klausimų?',
        answer: (
            <p>
                Visais klausimais kreipkitės e-paštu:{' '}
                <a href="mailto:info@deklaruok24.lt">info@deklaruok24.lt</a>.
            </p>
        )
    },
    {
        question:
            'Jeigu siuntos vertė nurodyta užsienio valiuta, kaip skaičiuojant importo mokesčius ji perskaičiuojama į eurus?',
        answer: (
            <>
                <p>
                    Importuojamų prekių, kurios įvertintos užsienio valiuta,
                    muitinė vertė eurais apskaičiuojama pagal valiutos kursą,
                    galiojantį importo deklaracijos priėmimo dieną.
                </p>
                <p>
                    Galiojantys euro ir kitų valiutų santykiai nustatomi
                    vadovaujantis Sąjungos muitinės kodekso įgyvendinimo akto
                    146 straipsnyje nustatyta tvarka. Euro ir kitų valiutų
                    santykiai skelbiami{' '}
                    <a
                        href="http://www.lrmuitine.lt/web/guest/767"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Lietuvos muitinės tinklalapyje
                    </a>
                    .
                </p>
            </>
        )
    }
]

export default items
