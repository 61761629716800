import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from '@utils/classNames'

const notEmpty = (value) => {
    if (value === null) return false
    if (typeof value === 'object' && value.length === 0) return false
    return value || value === 0
}

const Input = ({ label, size, className, input, tooltip, error }) => {
    const [active, setActive] = useState(false)
    const value = input.value
    const [fileName, setFileName] = useState(false)

    const [hasValue, setHasValue] = useState(notEmpty(value))

    useEffect(() => {
        if (notEmpty(value)) {
            setHasValue(true)
        } else {
            setHasValue(false)
        }
    }, [value])

    const handleBlur = (e) => {
        if (!e.currentTarget.value) setActive(false)
    }

    const handleChange = (e) => {
        if (input && input.type === 'file') {
            const fullPath = e.target.value
            const startIndex =
                fullPath.indexOf('\\') >= 0
                    ? fullPath.lastIndexOf('\\')
                    : fullPath.lastIndexOf('/')
            let filename = fullPath.substring(startIndex)
            if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
                filename = filename.substring(1)
            }
            setFileName(filename)
        }
        if (input && input.onChange) {
            input.onChange(e)
        }
    }

    const props = {
        ...input,
        value: input && input.type == 'file' ? '' : input ? input.value : null
    }

    return (
        <div
            className={cx(
                'Form__control',
                tooltip && 'Form__control--has-tooltip',
                (active || fileName || hasValue) && 'isActive',
                error && 'hasError',
                className
            )}
        >
            <input
                onFocus={() => setActive(true)}
                onBlur={handleBlur}
                className={cx(
                    'Form__input',
                    `Form__input--${size}`,
                    'text-black bg-white'
                )}
                {...props}
                onChange={handleChange}
            />
            {fileName && (
                <label className="Form__label fileName text-grey-dark">
                    {fileName}
                </label>
            )}
            {label && (
                <label className="Form__label text-grey-dark">
                    {label?.text}
                </label>
            )}

            {tooltip && (
                <div className={cx('Form__tooltip', `Form__tooltip--${size}`)}>
                    {tooltip}
                </div>
            )}

            {input.type === 'file' && (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="192"
                    height="192"
                    viewBox="0 0 256 256"
                    className="fileIcon"
                >
                    <path
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="16"
                        d="M86 81.989 128 40l42 41.989M128 152V40.02943M216 152v56a8 8 0 0 1-8 8H48a8 8 0 0 1-8-8v-56"
                    />
                </svg>
            )}
        </div>
    )
}

Input.propTypes = {
    size: PropTypes.oneOf(['l', 'm']),
    input: PropTypes.shape({}),
    label: PropTypes.shape({
        text: PropTypes.node
    }),
    className: PropTypes.string,
    tooltip: PropTypes.node,
    error: PropTypes.bool
}

Input.defaultProps = {
    size: 'm'
}

export default Input
