import useTranslation from 'next-translate/useTranslation'
import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'

import { Container, Grid, Link } from '@ui'
import useMobile from '@utils/useMobile'
import cx from '@utils/classNames'
import { ReactComponent as Logo } from '../assets/logo.svg'
//import { ReactComponent as PhoneIcon } from '../assets/icons/phone-icon.svg'
import { ReactComponent as EmailIcon } from '../assets/icons/email-icon.svg'
import { ReactComponent as UserIcon } from '../assets/icons/user-icon.svg'
import { ReactComponent as ArrowDown } from '../assets/icons/arrow-down.svg'
import { ReactComponent as MenuIcon } from '../assets/icons/menu.svg'
import useAppState from 'utils/state'
import setLanguage from 'next-translate/setLanguage'
import { Modal, HowTo } from '@components'

const Header = () => {
    const { t, lang } = useTranslation('common')
    const [showNav, setShowNav] = useState(false)
    const { pathname, asPath } = useRouter()
    const { isMobile } = useMobile()
    const { setShowHowTo, setShowFAQ } = useAppState()

    const languages = ['lt', 'en', 'ru', 'ua']
    const pagesInModal =
        pathname.startsWith('/declare') ||
        pathname.includes('/additional-data') ||
        pathname.includes('/extra-data') ||
        pathname.includes('/missing-data')

    useEffect(() => {
        setShowNav(false)
    }, [pathname])

    const handleFAQ = () => setShowFAQ(true)

    return (
        <header className="Header bg-white">
            <Container noGutter className="Header__container">
                <Grid className="Header__grid">
                    <Link to="/" text={<Logo className="Header__logo" />} />
                    <button
                        onClick={() => setShowNav(!showNav)}
                        className="Header__burger"
                    >
                        <MenuIcon fill={showNav ? '#fff' : '#231F20'} />
                    </button>
                    {isMobile && (
                        <div className="extraLangs">
                            {languages.map((lan) => (
                                <Link
                                    key={lan}
                                    to={asPath}
                                    locale={lan}
                                    onClick={() => setShowNav(false)}
                                    className={lang === lan ? "m:ml-3 activeExtraLang" : "m:ml-3"}
                                    text={lan.toUpperCase()}
                                />
                            ))}
                        </div>
                    )}
                    <nav className={cx('Header__nav', showNav && 'isActive')}>
                        <ul className="Header__nav-primary">
                            <li>
                                {pagesInModal ? (
                                    <Link
                                        onClick={() => setShowHowTo(true)}
                                        color="black"
                                        text={t('header_link_how')}
                                    />
                                ) : (
                                    <Link
                                        active={pathname == '/#how'}
                                        to="/#how"
                                        color="black"
                                        text={t('header_link_how')}
                                    />
                                )}
                            </li>
                            <li>
                                {pagesInModal ? (
                                    <Link
                                        onClick={handleFAQ}
                                        color="black"
                                        text={t('header_link_faq')}
                                    />
                                ) : (
                                    <Link
                                        to="/#faq"
                                        color="black"
                                        text={t('header_link_faq')}
                                    />
                                )}
                            </li>
                            <li>
                                <Link
                                    active={pathname == '/about'}
                                    to="/about"
                                    color="black"
                                    text={t('header_link_about')}
                                />
                            </li>
                            <li>
                                <Link
                                    active={pathname == '/contacts'}
                                    to="/contacts"
                                    color="black"
                                    text={t('header_link_contacts')}
                                />
                            </li>
                        </ul>
                        <ul className="Header__nav-secondary">
                            <li>
                                <Link
                                    href="mailto:info@deklaruok24.lt"
                                    color="black"
                                    text={
                                        <>
                                            <EmailIcon
                                                className="text-violet"
                                                width="3.2rem"
                                                height="2.7rem"
                                            />
                                            <span className="pl-2 font-semi-bold">
                                                info@deklaruok24.lt
                                            </span>
                                        </>
                                    }
                                />
                            </li>
                            {/*<li>
                                <Link
                                    to="/"
                                    color="black"
                                    text={
                                        <>
                                            <UserIcon className="text-violet" width="1.8rem" height="2.3rem" />
                                            <span className="pl-2">Prisijungti</span>
                                        </>
                                    }
                                />
                            </li>*/}
                            {isMobile ? (
                                <li className="m:mt-10">
                                    {languages.map((lang) => (
                                        <Link
                                            key={lang}
                                            to={asPath}
                                            locale={lang}
                                            onClick={() => setShowNav(false)}
                                            color="white"
                                            className="m:ml-3"
                                            text={lang.toUpperCase()}
                                        />
                                    ))}
                                </li>
                            ) : (
                                <li>
                                    <div className="Header__lang">
                                        <div className="Header__lang-tile">
                                            <Link
                                                color="black"
                                                text={lang.toUpperCase()}
                                            />
                                            <ArrowDown width="1.1rem" />
                                        </div>
                                        <div className="Header__lang-content">
                                            <ul>
                                                {languages
                                                    .filter((l) => l != lang)
                                                    .map((lang) => (
                                                        <li key={lang}>
                                                            <Link
                                                                to={asPath}
                                                                color="black"
                                                                locale={lang}
                                                                text={lang.toUpperCase()}
                                                            />
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            )}
                        </ul>
                    </nav>
                </Grid>
            </Container>
        </header>
    )
}

export default Header

// <li className="m:mt-10">
//     <Link
//         onClick={async () => {
//             setShowNav(false)
//             await setLanguage('lt')
//         }}
//         color="white"
//         text="LT"
//     />
//     <Link
//         onClick={async () => {
//             setShowNav(false)
//             await setLanguage('en')
//         }}
//         className="m:ml-3"
//         color="white"
//         text="EN"
//     />
// </li>
