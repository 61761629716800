import PropTypes from 'prop-types'
import { TextBlock } from '@ui'
import cx from '@utils/classNames'

const Message = ({ color, className, children }) => {
    return (
        <div className={cx('Message', `Message--${color}`, className)}>
            <TextBlock className="font-medium">{children}</TextBlock>
        </div>
    )
}

Message.propTypes = {
    className: PropTypes.string,
    color: PropTypes.oneOf(['violet', 'red'])
}

Message.defaultProps = {
    color: 'violet'
}

export default Message
