import { createContext, useState, useContext, useEffect } from 'react'
import { useRouter } from 'next/router'
import { get } from '@utils/api'
import { Modal, HowTo, Faq } from '@components'
import useTranslation from 'next-translate/useTranslation'

const StateContext = createContext({
    shipment: {},
    attempts: 0,
    setCaptcha: () => {},
    loading: false,
    setShipment: () => {},
    setLoading: () => {},
    loadingShipment: true,
    searchShipment: () => {},
    setShowHowTo: () => {},
    setShowFAQ: () => {}
})

export function StateContextProvider({ children }) {
    const router = useRouter()
    const { t } = useTranslation('common')

    const [loading, setLoading] = useState(false)
    const [attempts, setAttempts] = useState(0)
    const [captcha, setCaptcha] = useState('')
    const [searching, setSearching] = useState(false)
    const [loadingShipment, setLoadingShipment] = useState(true)
    const [shipment, setShipment] = useState()
    const [paymentId, setPaymentId] = useState()
    const [showFAQ, setShowFAQ] = useState(false)
    const [showHowTo, setShowHowTo] = useState(false)

    const updateShipment = (data) => {
        setShipment({ ...shipment, ...data })
        // setCookie('shipment', data.tracking_no, {
        //     path: '/',
        //     sameSite: true,
        //     options: { maxAge: 60 * 30 } // pusė val
        // })
    }

    const searchShipment = async (data, redirect, warning = true) => {
        if (searching) return

        if (!captcha && attempts > 3) {
            alert(t('check_recapthcha'))
            return
        }

        setSearching(true)
        const response = await get('search-shipment', { ...data, captcha })
        setTimeout(() => {
            setSearching(false)
        }, 1000)

        if (response?.data) {
            if (response.data.error) {
                setAttempts(attempts + 1)
            } else {
                setAttempts(0)
            }
            if (response.data.error && warning) {
                await router.push('/')
                setTimeout(() => {
                    if (response.data.error === 'Siunta jau apmokėta.') {
                        alert(t('shipment_already_paid'))
                    } else if (response.data.error === 'Siunta nerasta.') {
                        alert(t('shipment_not_found'))
                    } else if (response.data.error === 'Trūksta duomenų.') {
                        alert(t('shipment_missing_data'))
                    } else if (response.data.error === 'missing_h1_shipment_vat_person') {
                        alert(t('shipment_missing_h1_shipment_vat_person'))
                    } else if (response.data.error === 'missing_h1_shipment_vat_company') {
                        alert(t('shipment_missing_h1_shipment_vat_company'))
                    } else {
                        alert(response.data.error)
                    }
                }, 1000)
            }
            if (response.status === 200) {
                updateShipment({
                    ...response.data.data,
                    confirmed: data.nr ? 0 : 1
                })
                if (redirect) {
                    if (data.nr) {
                        router.push(
                            `/declare?nr=${response.data.data.tracking_no}`
                        )
                    } else {
                        router.push(
                            `/declare/1?nr=${response.data.data.tracking_no}`
                        )
                    }
                }
            }
        } else {
            console.log(response)
        }
        setLoadingShipment(false)
    }

    // const searchShipment = async (nr, redirect, warning = true) => {
    //     const response = await get('search-shipment', { nr })
    //     if (response.data) {
    //         if (response.data.error && warning) {
    //             alert(response.data.error)
    //         }
    //         if (response.status === 200) {
    //             updateShipment(response.data.data)
    //             if (redirect) {
    //                 router.push('declare/1')
    //             }
    //         }
    //     }
    //     setLoadingShipment(false)
    // }

    // useEffect(() => {
    //     if (!shipment && cookie.shipment) {
    //         searchShipment(cookie.shipment, null, false)
    //     } else {
    //         setLoadingShipment(false)
    //     }
    // }, [cookie])

    const context = {
        attempts,
        loading,
        setLoading,
        shipment,
        setShipment,
        loadingShipment,
        updateShipment,
        searchShipment,
        setShowHowTo,
        setCaptcha,
        setShowFAQ,
        paymentId,
        setPaymentId
    }

    return (
        <StateContext.Provider value={context}>
            {children}
            <Modal
                isOpen={showHowTo}
                onRequestClose={() => setShowHowTo(false)}
                contentLabel=""
                big
            >
                <div>
                    <HowTo value={showHowTo} />
                </div>
            </Modal>
            <Modal
                isOpen={showFAQ}
                onRequestClose={() => setShowFAQ(false)}
                contentLabel=""
            >
                <div>
                    <Faq />
                </div>
            </Modal>
        </StateContext.Provider>
    )
}

export default function useAppState() {
    return useContext(StateContext)
}
