import ReactModal from 'react-modal'
import PropTypes from 'prop-types'
import { ReactComponent as CloseIcon } from '../assets/icons/close.svg'
import cx from '@utils/classNames'

ReactModal.setAppElement('#modal')

const Modal = ({ children, onRequestClose, big, stripe, ...rest }) => {
    return (
        <ReactModal
            onRequestClose={onRequestClose}
            overlayClassName="Modal__overlay"
            className="Modal__content"
            {...rest}
        >
            <div
                className={cx(
                    'Modal__container',
                    big && 'Modal__container_big',
                    stripe && 'Modal__container_stripe'
                )}
            >
                <button
                    onClick={() => onRequestClose()}
                    className="Modal__close"
                    type="button"
                    aria-label="close"
                >
                    <CloseIcon />
                </button>
                {children}
            </div>
        </ReactModal>
    )
}

Modal.propTypes = {
    children: PropTypes.node
}

export default Modal
