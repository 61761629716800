export default {
    code: 'LT',
    name: 'Lietuva',
    currency: 'EUR',
    defaultLanguage: 'LT',
    languages: ['LT', 'EN', 'RU', 'PL'],
    aspsps: [
        {
            code: 'card',
            name: 'Mokėjimo kortelė',
            bic: 'PAUALT22'
        },
        {
            code: 'swedbank',
            name: '„Swedbank” AB LT',
            bic: 'HABALT22',
            services: ['pisp', 'aisp', 'rpas', 'pcsp'],
            logo:
                'https://asset.neopay.lt/images/logos/aspsp/60828d5c141ef925026215.png',
            isOperating: true
        },
        {
            code: 'seb',
            name: 'AB "SEB bankas" LT',
            bic: 'CBVILT2X',
            services: ['pisp', 'aisp', 'rpas', 'pcsp'],
            logo:
                'https://asset.neopay.lt/images/logos/aspsp/5ef99c8e6d9c8951974658.png',
            isOperating: true
        },
        {
            code: 'citadele',
            name: 'Citadele',
            bic: 'INDULT2X',
            services: ['pisp', 'aisp'],
            logo:
                'https://asset.neopay.lt/images/logos/aspsp/5ef99caf18ce0840396727.png',
            isOperating: true
        },
        {
            code: 'luminor',
            name: 'Luminor LT',
            bic: 'AGBLLT2X',
            services: ['pisp', 'aisp', 'pcsp'],
            logo:
                'https://asset.neopay.lt/images/logos/aspsp/5ef9a3cef13ca487840850.png',
            isOperating: true
        },
        {
            code: 'revolut',
            name: 'Revolut LT',
            bic: 'REVOLT21',
            services: ['pisp'],
            logo:
                'https://asset.neopay.lt/images/logos/aspsp/5fb687cd1802d268647687.png',
            isOperating: true
        },
        {
            code: 'siauliubankas',
            name: 'Šiaulių bankas',
            bic: 'CBSBLT26',
            services: ['pisp'],
            logo:
                'https://asset.neopay.lt/images/logos/aspsp/5ef99ccbd3f18346274437.png',
            isOperating: true
        },
        {
            code: 'medicinosbankas',
            name: 'Medicinos bankas',
            bic: 'MDBALT22',
            services: ['pisp'],
            logo:
                'https://asset.neopay.lt/images/logos/aspsp/5ebcf1e52d9fd963834624.png',
            isOperating: true
        },
        {
            code: 'neofinance',
            name: 'NEO Finance, AB',
            bic: 'UANFLT21',
            services: ['pisp'],
            logo:
                'https://asset.neopay.lt/images/logos/aspsp/5e1594227550c320029766.png',
            isOperating: true
        },
        {
            code: 'lcku',
            name: 'Lietuvos centrinė kredito unija',
            bic: 'LCKULT22',
            services: ['pisp'],
            logo:
                'https://asset.neopay.lt/images/logos/aspsp/5ef99ce6a2053205060034.png',
            isOperating: true
        },
        {
            code: 'ratounija',
            name: 'RATO',
            bic: 'VRKULT21',
            services: ['pisp'],
            logo:
                'https://asset.neopay.lt/images/logos/aspsp/5fc8d55e267e4026307184.png',
            isOperating: true
        },
        {
            code: 'kreda',
            name: 'Kreda',
            bic: 'JCKULT21',
            services: ['pisp'],
            logo:
                'https://asset.neopay.lt/images/logos/aspsp/5ef99d313a6ee089256684.png',
            isOperating: true
        },
        {
            code: 'paysera',
            name: 'Paysera LT',
            bic: 'EVIULT2V',
            services: ['pisp'],
            logo:
                'https://asset.neopay.lt/images/logos/aspsp/5eb011fa9ae08123819235.png',
            isOperating: true
        }
    ],
    rules: {
        LT:
            'Paslaugą teikia NEO Finance, AB. Naudodamiesi šia paslauga, patvirtinate, kad susipažinote su taisyklėmis</a>',
        EN:
            'This Service is provided by NEO Finance, AB. By using this service, you thereby confirm that you have read the rules</a>',
        RU:
            'Услугу предоставляет NEO Finance, AB. Используя этот сервис, вы подтверждаете, что ознакомились с правилами.</a>',
        PL:
            'Usługa jest świadczona przez NEO Finance, AB. Korzystając z tej usługi, potwierdzasz, że zapoznałeś(-aś) się z zasadami</a>'
    }
}
