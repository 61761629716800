import cx from '@utils/classNames'
import banks from '@utils/banks'
import useTranslation from 'next-translate/useTranslation'

const hasLogo = [
    'swedbank',
    'luminor',
    'medicinosbankas',
    'neofinance',
    'paysera',
    'ratounija',
    'revolut',
    'seb',
    'siauliubankas',
    'kreda',
    'lcku',
    'citadele',
    'card'
]

const getLogo = (bank) => {
    if (hasLogo.indexOf(bank.code) > -1) {
        return `/banks/${bank.code}.${bank.code == 'card' ? 'svg' : 'png'}`
    }
    return bank.logo
}

const isDev = process.env.NEXT_PUBLIC_APP_ENV === 'dev'

const BankLinks = ({ className, onChange }) => {
    const { t } = useTranslation('declare')

    return (
        <div className={cx('BankLinks', className)}>
            <div className="BankLinks__container">
                {banks.aspsps.map((bank) => (
                    <label key={bank.code} className="BankLinks__item">
                        <img src={getLogo(bank)} alt="logo" />
                        <input
                            type="radio"
                            name="bank"
                            value={bank.bic}
                            onChange={onChange}
                        />
                        <span className="BankLinks__checkmark" />
                        {bank.code === 'card' && (
                            <b className="BankLinks__info">{t('bank_card')}</b>
                        )}
                    </label>
                ))}
                {!!isDev && (
                    <label className="BankLinks__item">
                        <input
                            type="radio"
                            name="bank"
                            value="test"
                            onChange={onChange}
                        />
                        <span className="bankText">Test</span>
                        <span className="BankLinks__checkmark" />
                    </label>
                )}
            </div>
        </div>
    )
}

export default BankLinks
