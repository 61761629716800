import { createElement } from 'react'
import Link from 'next/link'
import PropTypes from 'prop-types'
import cx from '@utils/classNames'

const Button = ({ size, href, to, variant, text, color, className, ...rest }) => {
    let resolvedStyle = ''
    const defaultStyle = cx('Button', `Button--${variant}`)

    switch (color) {
        case 'green':
            if (variant === 'primary') {
                resolvedStyle = 'text-white bg-green'
            }
            if (variant === 'secondary') {
                resolvedStyle = 'text-green border-green'
            }
            break
        case 'red':
            if (variant === 'primary') {
                resolvedStyle = 'text-white bg-red'
            }
            if (variant === 'secondary') {
                resolvedStyle = 'text-red border-red'
            }
            break
        default:
            return
    }

    if (to)
        return (
            <Link href={to}>
                <a className={cx(defaultStyle, resolvedStyle, className)} {...rest}>
                    {text}
                </a>
            </Link>
        )

    return createElement(
        href ? 'a' : 'button',
        { className: cx(defaultStyle, resolvedStyle, className), href, ...rest },
        text
    )
}

Button.propTypes = {
    variant: PropTypes.oneOf(['primary', 'secondary']),
    color: PropTypes.oneOf(['green', 'red']),
    text: PropTypes.string.isRequired,
    className: PropTypes.string
}

Button.defaultProps = {
    color: 'green',
    variant: 'primary'
}

export default Button
