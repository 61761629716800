import { createElement } from 'react'
import PropTypes from 'prop-types'
import ReactLink from 'next/link'
import cx from '@utils/classNames'

const Link = ({
    to,
    href,
    text,
    onClick,
    locale,
    hasUnderline,
    color,
    className,
    active,
    ...rest
}) => {
    const resolvedStyle = cx(
        'Link',
        hasUnderline && 'Link--has-underline',
        `text-${color}`,
        active && 'isActive',
        className && className
    )

    if (to)
        return (
            <ReactLink href={to} locale={locale}>
                <a onClick={onClick} className={resolvedStyle} {...rest}>
                    {text}
                </a>
            </ReactLink>
        )

    if (onClick) {
        return (
            <span onClick={onClick} className={resolvedStyle} {...rest}>
                {text}
            </span>
        )
    }

    return createElement(
        href ? 'a' : 'button',
        { className: resolvedStyle, href, ...rest },
        text
    )
}

Link.propTypes = {
    color: PropTypes.oneOf(['green', 'black', 'white', 'grey-dark']),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    className: PropTypes.string,
    active: PropTypes.bool,
    hasUnderline: PropTypes.bool
}

Link.defaultProps = {
    color: 'green',
    active: false
}

export default Link
