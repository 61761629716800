import PropTypes from 'prop-types'
import cx from '@utils/classNames'

const Section = ({ children, className, ...rest }) => {
    return (
        <section className={cx('Section', className)} {...rest}>
            {children}
        </section>
    )
}

Section.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
}

export default Section
