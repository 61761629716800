import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Input, Button } from '@ui'
import useAppState from '@utils/state'
import ReCAPTCHA from 'react-google-recaptcha'
import useTranslation from 'next-translate/useTranslation'

const Search = ({ className, label }) => {
    const { lang, t } = useTranslation('home')
    const { searchShipment, attempts, setCaptcha } = useAppState()
    const [nr, setNr] = useState('')
    const needCaptcha = attempts > 3
    let grecaptchaObject

    useEffect(() => {
        window.recaptchaOptions = {
            useRecaptchaNet: true
        }
        grecaptchaObject = window.grecaptcha
    })

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (nr) {
            searchShipment({ nr }, true)
        }
    }

    return (
        <>
            <form
                onSubmit={handleSubmit}
                className={`Search ${className && className}`}
            >
                <Input
                    label={label}
                    type="search"
                    size="l"
                    input={{
                        value: nr,
                        onChange: (e) => setNr(e.target.value)
                    }}
                />
                <Button
                    className="Search__button"
                    text={t('declare')}
                    type="submit"
                />
            </form>
            {needCaptcha && (
                <div className="SearchCaptcha">
                    <ReCAPTCHA
                        sitekey="6Le78sgcAAAAAJtT6a2GdHNk7C670qGF1folyWL4"
                        onChange={(v) => setCaptcha(v)}
                        onExpired={() => setCaptcha(null)}
                        grecaptcha={grecaptchaObject}
                        hl={lang}
                    />
                </div>
            )}
        </>
    )
}

Search.propTypes = {
    className: PropTypes.string,
    label: PropTypes.shape({})
}

export default Search
