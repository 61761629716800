import PropTypes from 'prop-types'
import cx from '@utils/classNames'

const Checkbox = ({ label, className, checkbox }) => {
    return (
        <label className={cx('Form__control inline-flex', className)}>
            <input
                className={cx('Form__checkbox')}
                type="checkbox"
                {...checkbox}
            />
            {label && (
                <span className="Form__label text-grey-dark">
                    {label?.text}
                </span>
            )}
        </label>
    )
}

Checkbox.propTypes = {
    checkbox: PropTypes.shape({}),
    label: PropTypes.shape({
        text: PropTypes.node
    }),
    className: PropTypes.string
}

export default Checkbox
