import useTranslation from 'next-translate/useTranslation'

import { Grid, Col, TextBlock } from '@ui'
import Step1Icon from '../assets/about-us/about-step-1.svg'
import Step2Icon from '../assets/about-us/about-step-2.svg'
import Step3Icon from '../assets/about-us/about-step-3.svg'

const AboutUsCards = () => {
    const { t } = useTranslation('about')

    return (
        <Grid className="AboutUsCards">
            <Col className="AboutUsCards__item bg-white shadow-2">
                <Step1Icon />
                <TextBlock>
                    <p dangerouslySetInnerHTML={{ __html: t('block_1') }} />
                </TextBlock>
            </Col>
            <Col className="AboutUsCards__item bg-white shadow-2">
                <Step2Icon />
                <TextBlock>
                    <p dangerouslySetInnerHTML={{ __html: t('block_2') }} />
                </TextBlock>
            </Col>
            <Col className="AboutUsCards__item bg-white shadow-2">
                <Step3Icon />
                <TextBlock>
                    <p dangerouslySetInnerHTML={{ __html: t('block_3') }} />
                </TextBlock>
            </Col>
        </Grid>
    )
}

export default AboutUsCards
