import { Header, Footer, CookieConsent, DeveloperNav } from '@components'
import useAppState from 'utils/state'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import Cookies from 'universal-cookie'
import setLanguage from 'next-translate/setLanguage'

function usePersistLocaleCookie() {
    const cookies = new Cookies()
    const { locale, defaultLocale } = useRouter()

    useEffect(() => {
        const NEXT_LOCALE = cookies.get('NEXT_LOCALE')
        if (NEXT_LOCALE) {
            change(NEXT_LOCALE)
        }
    }, [])

    const change = async (lang) => {
        await setLanguage(lang)
    }

    useEffect(persistLocaleCookie, [locale, defaultLocale])
    function persistLocaleCookie() {
        cookies.set('NEXT_LOCALE', locale, {
            path: '/',
            expires: new Date(Date.now() + 31536000000)
        })
        // if (locale !== defaultLocale) {
        //     const date = new Date()
        //     const expireMs = 100 * 24 * 60 * 60 * 1000 // 100 days
        //     date.setTime(date.getTime() + expireMs)
        //     document.cookie = `NEXT_LOCALE=${locale};expires=${date.toUTCString()};path=/`
        // }
    }
}

function Layout(props) {
    const loc = usePersistLocaleCookie()
    const { loading } = useAppState()

    return (
        <>
            <Header />
            {props.children}
            <CookieConsent />
            <Footer />
            {loading && <div className="loader" />}
        </>
    )
}

export default Layout
