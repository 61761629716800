import PropTypes from 'prop-types'
import cx from '@utils/classNames'

const Container = ({ children, noGutter, className, ...rest }) => {
    return (
        <div className={cx('Container', noGutter && 'Container--no-gutter', className)} {...rest}>
            {children}
        </div>
    )
}

Container.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    noGutter: PropTypes.bool
}

Container.default = {
    className: '',
    noGutter: false
}

export default Container
