import React, { useState, useRef, useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'

import { Heading, Button, TextBlock } from '@ui'

const StripeForm = ({ handleHideModal }) => {
    const stripe = useStripe()
    const elements = useElements()
    const { t } = useTranslation('declare')
    const el = useRef()

    const [ready, setReady] = useState(false)
    const [disabled, setDisabled] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)

    useEffect(() => {
        setReady(false)
    }, [])

    useEffect(() => {
        if (elements) {
            const ell = elements.getElement(PaymentElement)
            ell.on('ready', () => {
                setReady(true)
            })
        }
    }, [elements])

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault()

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return
        }

        setDisabled(true)

        const { error } = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: process.env.NEXT_PUBLIC_D24 + '/declare/4'
            }
        })

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage(error.message)
            setDisabled(false)
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <Heading
                text={t('stripe_modal_title')}
                className="mb-4 m:mb-6"
                size="s"
                component="h2"
            />
            {disabled && (
                <TextBlock>
                    {t('waiting_for_the_payment')}
                    <br />
                    <br />
                </TextBlock>
            )}
            {!ready && (
                <TextBlock>
                    {t('please_wait')}
                    <br />
                    <br />
                </TextBlock>
            )}
            <div
                style={{
                    position: 'relative',
                    display: ready ? 'block' : 'none'
                }}
            >
                {disabled && (
                    <div
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            zIndex: 1000,
                            left: 0,
                            top: 0,
                            backgroundColor: 'rgba(255,255,255,.8)'
                        }}
                    ></div>
                )}
                <PaymentElement ref={el} />
            </div>
            <div style={{ marginTop: '4em' }}>
                {!disabled && ready && (
                    <Button
                        disabled={!stripe}
                        text={t('stripe_modal_submit')}
                    />
                )}
                {/* Show error message to your customers */}
                {errorMessage && <TextBlock>{errorMessage}</TextBlock>}
            </div>
        </form>
    )
}

export default StripeForm
