import useTranslation from 'next-translate/useTranslation'
import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import Cookies from 'universal-cookie'

import { TextBlock, Checkbox, Button, Link } from '@ui'

// <Link
//     href="/privacy-policy"
//     target="_blank"
//     text="Sužinoti daugiau"
//     color="white"
//     hasUnderline
// />

const CookieConsent = () => {
    const { t } = useTranslation('common')
    const cookies = new Cookies()
    const trevioCookie = cookies.get('trevioCookie')
    const [showCookie, setShowCookie] = useState(false)
    const [step, setStep] = useState(1)
    const [state, setState] = useState({
        analytical: 0,
        advertising: 0,
        functional: 1
    })

    useEffect(() => {
        setShowCookie(!trevioCookie)
    }, [trevioCookie])

    const { pathname } = useRouter()

    const setCookieHandler = () => {
        cookies.set('trevioCookie', state, {
            path: '/',
            expires: new Date(Date.now() + 31536000000)
        })
        setShowCookie(false)
    }

    if (!showCookie || pathname === '/declare/4') return null

    return (
        <div className="CookieConsent bg-violet">
            <div className="CookieConsent__content">
                {step === 1 && (
                    <TextBlock color="white">
                        <p>
                            <b>{t('cookies_title')}</b>
                        </p>
                        <p>{t('cookies_text')} </p>
                    </TextBlock>
                )}
                {step === 2 && (
                    <TextBlock color="white">
                        <Checkbox
                            checkbox={{
                                required: true,
                                checked: !!state.analytical,
                                onChange: (e) =>
                                    setState({
                                        ...state,
                                        analytical: e.target.checked ? 1 : 0
                                    })
                            }}
                            label={{
                                text: t('cookies_analytical_cta')
                            }}
                        />
                        <p className="small">
                            {t('cookies_analytical')}
                            <br />
                            <br />
                        </p>
                        <Checkbox
                            checkbox={{
                                required: true,
                                checked: !!state.advertising,
                                onChange: (e) =>
                                    setState({
                                        ...state,
                                        advertising: e.target.checked ? 1 : 0
                                    })
                            }}
                            label={{
                                text: t('cookies_promotional_cta')
                            }}
                        />
                        <p className="small">{t('cookies_promotional')}</p>
                    </TextBlock>
                )}
                <div className="fl mt-4">
                    <Button
                        text={t('cookies_agree')}
                        onClick={setCookieHandler}
                    />
                    {step === 1 && (
                        <Link
                            target="_blank"
                            text={t('cookies_settings')}
                            color="white"
                            hasUnderline
                            onClick={() => setStep(2)}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default CookieConsent
