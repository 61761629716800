import useTranslation from 'next-translate/useTranslation'

export default function ShipmentDetails({ shipment, minimal }) {
    const { t } = useTranslation('declare')

    return (
        <ul>
            <li>
                <p>
                    <strong>{t('shipment_nr')}:</strong> {shipment.tracking_no}{' '}
                    / {shipment.carrier}
                </p>
            </li>
            {!minimal && (
                <>
                    <li>
                        <p>
                            <strong>{t('exporter_name')}:</strong>{' '}
                            {shipment.exporter}
                        </p>
                    </li>
                    {/*<li>
                    <p>
                        <strong>{t('courier')}:</strong>{' '}
                        ITELLA/DPD/OMNIVA
                    </p>
                </li>*/}
                    <li>
                        <p>
                            <strong>{t('purchase_account_nr')}:</strong>{' '}
                            {shipment.invoice}
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>{t('date_of_purchase')}:</strong>{' '}
                            {shipment.date}
                        </p>
                    </li>
                </>
            )}
        </ul>
    )
}
