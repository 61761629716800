import useTranslation from 'next-translate/useTranslation'
import { useStripe } from '@stripe/react-stripe-js'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { Heading, Grid, TextBlock } from '@ui'
import { Success } from '@components'

export default function Declare4() {
    const router = useRouter()
    const [pageLoading, setPageLoading] = useState(true)
    const [failed, setFailed] = useState(0)
    const [paymentIntent, setPaymentIntent] = useState(0)
    const { t } = useTranslation('declare')
    const stripe = useStripe()
    const [message, setMessage] = useState(null)

    useEffect(() => {
        const hasQuery = router && router.query;
        const hasData = hasQuery && (router.query.data || router.query.token || router.query.payment_intent || router.query.completed);
    
        if (hasData && pageLoading) {
            if (hasQuery && router.query.payment_intent) {
                setPaymentIntent(router.query.payment_intent);
            } else {
                setFailed(hasQuery && router.query.canceled ? 1 : 2);
            }
            setPageLoading(false);
        }
    }, [router])    

    useEffect(() => {
        if (!stripe) {
            return
        }

        // Retrieve the "payment_intent_client_secret" query parameter appended to
        // your return_url by Stripe.js
        const clientSecret = new URLSearchParams(window.location.search).get(
            'payment_intent_client_secret'
        )

        if (!clientSecret) {
            return
        }

        // Retrieve the PaymentIntent
        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            // Inspect the PaymentIntent `status` to indicate the status of the payment
            // to your customer.
            //
            // Some payment methods will [immediately succeed or fail][0] upon
            // confirmation, while others will first enter a `processing` state.
            //
            // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
            switch (paymentIntent.status) {
                case 'succeeded':
                    setFailed(2)
                    // setMessage('Success! Payment received.')
                    break

                case 'processing':
                    setFailed(2)
                    // setMessage(
                    //     "Payment processing. We'll update you when payment is received."
                    // )
                    break

                case 'requires_payment_method':
                    setFailed(1)
                    // Redirect your user back to your payment page to attempt collecting
                    // payment again
                    // setMessage(
                    //     'Payment failed. Please try another payment method.'
                    // )
                    break

                default:
                    setFailed(1)
                    // setMessage('Something went wrong.')
                    break
            }
        })
    }, [stripe, paymentIntent])

    if (pageLoading) return null

    return (
        <Grid className="shadow-2 m:shadow-none bg-white m:bg-transparent p-8 m:px-0 m:pt-8 m:pb-0">
            {failed === 1 && (
                <>
                    <Heading
                        text={
                            <span className="inline-flex align-center">
                                {t('failure_title')}
                            </span>
                        }
                        className="mb-3 m:mb-3"
                        size="s"
                        component="h2"
                    />
                    <TextBlock className="TextBlock--mb">
                        <p
                            dangerouslySetInnerHTML={{
                                __html: t('failure_message')
                            }}
                        />
                    </TextBlock>
                </>
            )}
            {failed === 2 && (
                <>
                    <Success />
                    <TextBlock className="TextBlock--mb">
                        <p
                            dangerouslySetInnerHTML={{
                                __html: t('success_message')
                            }}
                        />
                    </TextBlock>
                </>
            )}
        </Grid>
    )
    // <Grid className="shadow-2 m:shadow-none bg-white m:bg-transparent p-8 mt-4 m:p-0">
    // <Heading
    // text="Turite daugiau siuntų, kurias reikia deklaruoti?"
    // className="mb-4"
    // size="s"
    // component="h2"
    // />
    // <Button to="/declare" text="Pirmyn" />
    // </Grid>
}
