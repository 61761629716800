import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import useTranslation from 'next-translate/useTranslation'

import StripeForm from './StripeForm'

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY)

export default function Stripe({ clientSecret, handleHideModal }) {
    const { lang } = useTranslation()

    const options = {
        // passing the client secret obtained in step 3
        clientSecret,
        locale: lang,
        // Fully customizable with appearance API.
        appearance: {
            /*...*/
        }
    }

    return (
        <Elements stripe={stripePromise} options={options}>
            <StripeForm handleHideModal={handleHideModal} />
        </Elements>
    )
}
